import React, { Component, Suspense } from "react";
import {
  Badge,
  Button,
  CardBody,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  Popover,
  PopoverBody,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { AppHeader } from "@coreui/react";
import DefaultHeader from "../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "./LeftInboxMenu";
import inboxicon from "../../assets/img/student/inbox-blue.svg";
import staredicon_InActive from "../../assets/img/student/inactive_star.svg";
import staredicon_Active from "../../assets/img/student/avtive_star.svg";
import livechat from "../../assets/img/student/live_chat.svg";
import calendaricon from "../../assets/img/student/calendar.svg";
import vectoricon from "../../assets/img/student/vector.svg";
import DeleteIcon from "../../assets/img/icons/inbox/mail_delete_icon.svg";
import AttachmentIcon from "../../assets/img/icons/inbox/attachemnt_icon.svg";
import DeleteAll from "../../assets/img/icons/inbox/delete_gray_icon.svg";
import Inbox from "../../assets/img/icons/inbox/inbox_gray_icon.svg";
import axios from "axios";
import Config from "../../config.json";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";

import PageLoading from "../../common/PageLoading";
import { MAIL_ARRANGEMENT_OPTIONS, MESSAGES } from "../../constant/messages";
import {
  changeFavourite,
  createMeeting,
  DeleteMsg,
  viewMeeting,
} from "./CommonMail";
import "./commonListing.scss";
import MailList from "./EmailListing.json";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getInboxAction,
  updateFavouriteAction,
  deleteEmailAction,
  deletePermAction,
  moveToInboxAction
} from "../../store/actions";
import PaginateElement from "../../common/Pagination";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const baseUrl = `${Config.inboxfiles}`;

class CommonListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "University",
      type_id: localStorage.getItem("studentid"),
      listingData: null,
      listingDataCopy: [],
      pageSize: 10,
      pageIndex: 0,
      mailCount: 0,
      todayDate: moment().format("DD MMM YY"),
      studentId: 0,
      unReadMailCount: 0,
      limit: 10,
      page: 1,
      order: "desc",
      filterOptions: [],
      selectedFilterOption: 1,
      displayFunction: null,
      popoverOpen: ""
    };
  }

  componentDidMount() {
    let param = localStorage.getItem("studentid");
    let filterOptions = [
      {
        val: 1,
        label: 'Newest on top'
      },
      {
        val: 2,
        label: 'Oldest on top'
      },
      {
        val: 3,
        label: 'Unread'
      },
      {
        val: 4,
        label: 'Sent to me'
      },
      {
        val: 5,
        label: 'Mentions'
      },
      {
        val: 6,
        label: 'Attachments'
      }
    ];
    let displayFunction = null
    switch (this.props.type) {
      case "Sent":
      case "Draft":
        displayFunction = this.getSentData;
        break;
      case "Deleted":
      case "Starred":
        displayFunction = this.getDeletedStarred;
        break;
      default:
        displayFunction = this.getInboxData;
    }

    this.setState({
      studentId: param,
      unReadMailCount: this.props.unreadCount,
      filterOptions,
      displayFunction
    }, () => {
      this.getData();
    })
  }

  componentDidUpdate(prevProps, preState) {

  }

  updateFavourite = (id) => {
    this.props.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateFavouriteAction(
        {
          student_id: this.state.studentId,
          mail_id: id
        },
        (response) => {
          this.props.hideLoading();
          if (response.success) {
            toast.success(response.message);
            this.getData()
          }
          resolve();
        },
        (err) => {
          this.props.hideLoading();
          reject();
        }
      )
    })
    this.props.hideLoading();
  }

  deleteEmail = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to permanently delete this email?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.showLoading();
            new Promise((resolve, reject) => {
              this.props.deleteEmailAction(
                {
                  student_id: this.state.studentId,
                  mail_id: id
                },
                (response) => {
                  this.props.hideLoading();
                  if (response.success) {
                    toast.success(response.message);
                    this.getData()
                  }
                  resolve();
                },
                (err) => {
                  this.props.hideLoading();
                  reject();
                }
              )
            })
            this.props.hideLoading();
          },
        },
        {
          label: "No",
          onClick: () => {

          },
        },
      ],
    });
  }

  getData = () => {
    this.props.showLoading();
    new Promise((resolve, reject) => {
      this.props.getInboxAction(
        {
          student_id: this.state.studentId,
          page: this.state.page,
          limit: this.state.limit,
          order: this.state.order,
          handle: this.props.handle,
          filter: this.state.selectedFilterOption
        },
        (response) => {
          this.props.hideLoading();
          if (response.success) {
            let listingData = response.data
            let data = listingData.data
            data.map(val => {
              let currentVal = val;
              currentVal.checked = false

              return currentVal
            })
            listingData.data = data
            this.setState({
              listingData
            });
          }
          resolve();
        },
        (err) => {
          console.log('log in err', err);
          this.props.hideLoading();
          reject();
        }
      )
    })
  };

  viewMessage = (param) => (e) => {
    if (this.props.type == "Draft") {
      this.props.history.push({
        pathname: this.props.pathName + "/" + param.id,
      });
    } else if (this.props.type == "Starred" && param.is_sent == 0) {
      this.props.history.push({
        pathname: 'inbox-compose' + "/" + param.id,
      });
    } else {
      this.props.history.push({
        pathname: this.props.pathName + "/" + param.id + "/" + this.props.type,
      });
    }
    // if (this.props.type == "Inbox") {

    // }
  };

  loading = () => <PageLoading loading={true} />;

  toggle = (popoverOpen) => {
    this.setState({
      popoverOpen
    })
  }

  getSentData = (item, index) => {
    return (
      <tr
        key={index.toString()}
        className={
          item.is_read ===
            0
            ? "new"
            : ""
        }
      >
        <td
          onClick={this.viewMessage(
            item
          )}
          className={`w250 from semiBold`}
        >
          <span
            className={`name ${this
              .props
              .type !==
              "Inbox" ?
              "full" : ""
              }`}
            id={'more_email_' + index}
          >
            {
              this
                .props
                .type ===
              "Draft" && (
                <>
                  <span className="draft">
                    Draft
                  </span>

                  :{" "}
                </>
              )
            }
            {
              this
                .props
                .type ===
              "Sent" && (
                <>
                  {"TO: "}
                </>
              )
            }
            {
              item.email_to &&
                item.email_to.length ?
                (
                  item.email_to.map((val, index) => {
                    if (
                      val.receiver ||
                      (val.receiver
                        && val.receiver.personal_details
                      )
                    ) {
                      if (
                        val.receiver.personal_details
                      ) {
                        return (index > 0 ? ", " : "") + val.receiver.personal_details.first_name
                          + " " +
                          val.receiver.personal_details.last_name
                      } else {
                        return (index > 0 ? ", " : "") + val.receiver.first_name
                          + " " +
                          val.receiver.last_name
                      }
                    } else {
                      return ""
                    }
                  })
                ) : ""
            }
          </span>

          {
            item.email_to &&
              item.email_to.length > 1 ? (
              <UncontrolledTooltip placement="bottom" target={'more_email_' + index}>
                {
                  item.email_to.map((val, index) => {
                    if (
                      val.receiver ||
                      (val.receiver
                        && val.receiver.personal_details
                      )
                    ) {
                      if (
                        val.receiver.personal_details
                      ) {
                        return (index > 0 ? ", " : "") + val.receiver.personal_details.first_name
                          + " " +
                          val.receiver.personal_details.last_name
                      } else {
                        return (index > 0 ? ", " : "") + val.receiver.first_name
                          + " " +
                          val.receiver.last_name
                      }
                    } else {
                      return ""
                    }
                  })
                }
              </UncontrolledTooltip>
            ) : null
          }

          {this
            .props
            .type ===
            "Inbox" &&
            item.created_date ===
            this
              .state
              .todayDate ? (
            <Badge color="new">
              New
            </Badge>
          ) : null}
        </td>
        <td
          onClick={this.viewMessage(
            item
          )}
          className={`noBorder subject ${item.is_read ===
            0
            ? "bold"
            : ""
            }`}
        >
          {
            item.subject
          }
        </td>
        <td className="action">
          {
            item.attachment_flag ? (
              <a className="calen-btn mr-3">
                <img
                  src={
                    AttachmentIcon
                  }
                  alt=""
                />
              </a>
            ) : null
          }
          {
            this
              .props
              .type ===
              "Deleted" ? null :
              (
                (
                  item.read_flag &&
                  item.read_flag[0] &&
                  item.read_flag[0].is_star
                ) ? (
                  <a className="calen-btn mr-3">
                    <img
                      src={
                        staredicon_Active
                      }
                      alt=""
                      onClick={() =>
                        this.updateFavourite(item.id)
                      }
                    />
                  </a>
                ) : (
                  <a className="calen-btn mr-3">
                    <img
                      src={
                        staredicon_InActive
                      }
                      alt=""
                      onClick={() =>
                        this.updateFavourite(item.id)
                      }
                    />
                  </a>
                )
              )
          }

          {this
            .props
            .type ===
            "Deleted" ? null : (
            <a className="delBtn">
              <img
                src={
                  DeleteIcon
                }
                width="16"
                alt=""
                onClick={() =>
                  this.deleteEmail(item.id)
                }
              />
            </a>
          )}
        </td>
        <td
          onClick={this.viewMessage(
            item
          )}
          className={`date text-center ${item.is_read ===
            0
            ? "bold"
            : ""
            }`}
        >
          <span className="time">{moment(moment.utc(item.time_sent)).local().format('H:MM a')}</span>
          {moment(moment.utc(item.time_sent)).local().format('DD MMM YY')}

          {/* <span className="time">
						{
							item.time_sent
						}
					</span> */}
        </td>
      </tr>
    )
  }

  getInboxData = (item, index) => {
    let mailBox = item.mailbox;
    if (mailBox) {
      return (
        <tr
          key={index.toString()}
          className={
            mailBox.read_flag &&
              mailBox.read_flag[0] &&
              mailBox.read_flag[0].is_unread
              ? "new"
              : ""
          }
        >
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`w250 from ${mailBox.read_flag &&
              mailBox.read_flag[0] &&
              mailBox.read_flag[0].is_unread
              ? "bold"
              : "semiBold"
              }`}
          >
            <span
              className={`name ${this
                .props
                .type !==
                "Inbox" ?
                "full" : ""
                }`}
            >
              {
                mailBox.email_from_details ?
                  (
                    mailBox.email_from_details.first_name +
                    " " +
                    mailBox.email_from_details.last_name
                  ) : ""
              }
            </span>

            {
              mailBox.read_flag &&
                mailBox.read_flag[0] &&
                mailBox.read_flag[0].is_unread ?
                (
                  <Badge color="new">
                    New
                  </Badge>
                ) : null
            }
          </td>
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`noBorder subject ${mailBox.read_flag &&
              mailBox.read_flag[0] &&
              mailBox.read_flag[0].is_unread
              ? "bold"
              : ""
              }`}
          >
            {/* {
							mailBox.parent_id ? (
								mailBox.parent_flag ?
									"Fwd: " :
									"Re: "
							) : ""
						} */}

            {
              mailBox.subject
            }
          </td>
          <td className="action">
            {
              mailBox.attachment_flag ? (
                <a className="calen-btn mr-3">
                  <img
                    src={
                      AttachmentIcon
                    }
                    alt=""
                  />
                </a>
              ) : null
            }
            {
              this
                .props
                .type ===
                "Deleted" ? null :
                (
                  (
                    mailBox.read_flag &&
                    mailBox.read_flag[0] &&
                    mailBox.read_flag[0].is_star
                  ) ? (
                    <a className="calen-btn mr-3">
                      <img
                        src={
                          staredicon_Active
                        }
                        alt=""
                        onClick={() =>
                          this.updateFavourite(mailBox.id)
                        }
                      />
                    </a>
                  ) : (
                    <a className="calen-btn mr-3">
                      <img
                        src={
                          staredicon_InActive
                        }
                        alt=""
                        onClick={() =>
                          this.updateFavourite(mailBox.id)
                        }
                      />
                    </a>
                  )
                )
            }

            {this
              .props
              .type ===
              "Deleted" ? null : (
              <a className="delBtn">
                <img
                  src={
                    DeleteIcon
                  }
                  width="16"
                  alt=""
                  onClick={() =>
                    this.deleteEmail(mailBox.id)
                  }
                />
              </a>
            )}
          </td>
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`date text-center ${mailBox.read_flag &&
              mailBox.read_flag[0] &&
              mailBox.read_flag[0].is_unread
              ? "bold"
              : ""
              }`}
          >
            <span className="time">{moment(moment.utc(item.time_sent)).local().format('H:MM a')}</span>
            {moment(moment.utc(item.time_sent)).local().format('DD MMM YY')}

            {/* <span className="time">
							{
								item.time_sent
							}
						</span> */}
          </td>
        </tr>
      )
    } else {
      return null;
    }
  }

  getDeletedStarred = (item, index) => {
    let mailBox = item.mailbox;
    if (mailBox) {
      return (
        <tr
          key={index.toString()}
          className={
            item.is_unread
              ? "new"
              : ""
          }
        >
          {
            this.props.type == "Deleted" ? (
              <td
                className="text-center no-border w30"
                valign='middle'
                style={{ position: "relative" }}
              >
                <Input
                  type="checkbox"
                  checked={item.checked}
                  onChange={(e) => {
                    let { listingData } = this.state
                    let data = listingData.data

                    data[index].checked = !data[index].checked
                    listingData.data = data

                    this.setState({
                      listingData
                    })
                  }}
                  className="p-0 m-0"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                />
              </td>
            ) : null
          }
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`w250 from ${item.is_read ===
              0
              ? "bold"
              : "semiBold"
              }`}
          >
            <span
              className={`name ${this
                .props
                .type !==
                "Inbox" &&
                "full"
                }`}
              id={'more_email_' + index}
            >
              {
                this
                  .props
                  .type ===
                  "Starred" && mailBox.is_sent == "0" ? (
                  <>
                    <span className="draft">
                      Draft
                    </span>
                    :{" "}
                  </>
                ) : null
              }
              {
                mailBox.sender_id ==
                  this.state.studentId ?
                  (
                    this
                      .props
                      .type ===
                      "Deleted" ? "Me" : (
                      <>
                        {mailBox.email_to.map((val, emailindex) => {
                          let nameString = "";
                          if (
                            val.receiver && val.receiver.personal_details
                          ) {
                            nameString = val.receiver.personal_details.first_name
                              + " " +
                              val.receiver.personal_details.last_name
                          } else {
                            nameString = val.receiver ? (val.receiver.first_name
                              + " " +
                              val.receiver.last_name) : ""
                          }

                          if (emailindex > 0) {
                            nameString = ", " + nameString;
                          }

                          return nameString;
                        })}
                        {
                          mailBox.email_to &&
                            mailBox.email_to.length > 1 ? (
                            <UncontrolledTooltip placement="bottom" target={'more_email_' + index}>
                              {
                                mailBox.email_to.map((val, emailindex) => {
                                  if (
                                    val.receiver ||
                                    (val.receiver
                                      && val.receiver.personal_details
                                    )
                                  ) {
                                    if (
                                      val.receiver.personal_details
                                    ) {
                                      return (emailindex > 0 ? ", " : "") + val.receiver.personal_details.first_name
                                        + " " +
                                        val.receiver.personal_details.last_name
                                    } else {
                                      return (emailindex > 0 ? ", " : "") + val.receiver.first_name
                                        + " " +
                                        val.receiver.last_name
                                    }
                                  } else {
                                    return ""
                                  }
                                })
                              }
                            </UncontrolledTooltip>
                          ) : null
                        }
                      </>
                    )
                  ) : (
                    mailBox.email_from_details ?
                      (
                        mailBox.email_from_details.first_name +
                        " " +
                        mailBox.email_from_details.last_name
                      ) : ""
                  )
              }
            </span>
          </td>
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`noBorder subject ${item.is_read ===
              0
              ? "bold"
              : ""
              }`}
          >
            {/* {
							mailBox.parent_id ? (
								mailBox.parent_flag ?
									"Fwd: " :
									"Re: "
							) : ""
						} */}
            {
              mailBox.subject
            }
          </td>
          <td className="action">
            {
              mailBox.attachment_flag ? (
                <a className="calen-btn mr-3">
                  <img
                    src={
                      AttachmentIcon
                    }
                    alt=""
                  />
                </a>
              ) : null
            }
            {
              this
                .props
                .type ===
                "Deleted" ? null :
                (
                  (
                    mailBox.read_flag &&
                    mailBox.read_flag[0] &&
                    mailBox.read_flag[0].is_star
                  ) ? (
                    <a className="calen-btn mr-3">
                      <img
                        src={
                          staredicon_Active
                        }
                        alt=""
                        onClick={() =>
                          this.updateFavourite(mailBox.id)
                        }
                      />
                    </a>
                  ) : (
                    <a className="calen-btn mr-3">
                      <img
                        src={
                          staredicon_InActive
                        }
                        alt=""
                        onClick={() =>
                          this.updateFavourite(mailBox.id)
                        }
                      />
                    </a>
                  )
                )
            }

            {this
              .props
              .type ===
              "Deleted" ? null : (
              <a className="delBtn">
                <img
                  src={
                    DeleteIcon
                  }
                  width="16"
                  alt=""
                  onClick={() =>
                    this.deleteEmail(mailBox.id)
                  }
                />
              </a>
            )}
          </td>
          <td
            onClick={this.viewMessage(
              mailBox
            )}
            className={`date text-center ${item.is_read ==
              0
              ? "bold"
              : ""
              }`}
          >
            <span className="time">{moment(moment.utc(item.time_sent)).local().format('H:MM a')}</span>
            {moment(moment.utc(item.time_sent)).local().format('DD MMM YY')}

            {/* <span className="time">
							{
								item.time_sent
							}
						</span> */}
          </td>
        </tr>
      )
    } else {
      return null;
    }
  }

  getCheckedIds = () => {
    return new Promise((resolved, reject) => {
      let ids = []
      if (
        this.state.listingData
        && this.state.listingData.data
        && this.state.listingData.data.length
      ) {
        console.log('log this.state.listingData.data', this.state.listingData.data);
        this.state.listingData.data.map(val => {
          if (val.checked) {
            ids.push(val.mailbox.id)
          }
        })
      }
      resolved(ids)
      return ids;
    })
  }

  deletePerm = (ids) => {
    if (ids.length) {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure you want to permanently delete selected emails?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.showLoading()
              this.props.deletePermAction({
                mail_id: ids,
                student_id: this.state.studentId
              }, (response) => {
                this.props.hideLoading()
                if (response.success) {
                  toast.success(response.message)
                  this.getData()
                }
              }, (err) => {
                this.props.hideLoading()
              })
            },
          },
          {
            label: "No",
            onClick: () => {

            },
          },
        ],
      });
    } else {
      toast.error('Please select an email to delete!')
    }
  }

  moveBack = (ids) => {
    if (ids.length) {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure you want to move the emails back to inbox?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.showLoading()
              this.props.moveToInboxAction({
                mail_id: ids,
                student_id: this.state.studentId
              }, (response) => {
                this.props.hideLoading()
                if (response.success) {
                  toast.success(response.message)
                  this.getData()
                }
              }, (err) => {
                this.props.hideLoading()
              })
            },
          },
          {
            label: "No",
            onClick: () => {

            },
          },
        ],
      });

    } else {
      toast.error('Please select an email to move!')
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col xs="12">
            <div className="mailListing">
              <header className="header">
                <h3 className="title">
                  {this.props.type}{" "}
                  {
                    this.props.type === "Inbox" ||
                      this.props.type === "Deleted" ? (
                      <Badge color="primary">
                        {this.props.unreadCount}
                      </Badge>
                    ) : null
                  }
                </h3>

                <div className="action">
                  {
                    this.props.type == "Deleted" ? (
                      <>
                        <Button
                          outline
                          className="mr-10"
                          onClick={async () => {
                            try {
                              let ids = await this.getCheckedIds()
                              this.deletePerm(ids)
                            } catch (e) {
                              console.log('log in err', e);
                            }
                          }}
                        >
                          <img
                            src={DeleteAll}
                          />
                          Delete Forever
                        </Button>

                        <Button
                          outline
                          onClick={async () => {
                            try {
                              let ids = await this.getCheckedIds()
                              this.moveBack(ids)
                            } catch (e) {
                              console.log('log in err', e);
                            }
                          }}
                        >
                          <img
                            src={Inbox}
                          />
                          Move to Inbox
                        </Button>
                      </>
                    ) : null
                  }

                  <select
                    className="form-control w150 filter-mail"
                    value={this.state.selectedFilterOption}
                    id=""
                    name=""
                    required=""
                    onChange={(e) => {
                      this.setState({
                        selectedFilterOption: e.target.value
                      }, () => {
                        this.getData()
                      })
                    }}
                  >
                    {this.state.filterOptions.map((mOpt) => (
                      <option
                        key={mOpt.val}
                        value={mOpt.val}
                        className="option"
                      >
                        {mOpt.label}
                      </option>
                    ))}
                  </select>
                </div>
              </header>
              {/* <Scrollbars
								className="overflowx-none"
								style={{ height: "calc(100vh - 335px)" }}
							> */}
              {
                (
                  this.state.listingData && this.state.listingData.data &&
                  this.state.displayFunction && this.state.listingData.data.length > 0
                ) ? (<div className="table-responsive">
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    className="mailPage"
                    style={{ tableLayout: "fixed" }}
                  >
                    <tbody>
                      {this.state.listingData.data
                        .map((item, index) => {
                          let element = this.state.displayFunction(item, index)
                          return element;
                        })}
                    </tbody>
                  </table>
                </div>)
                  : null}
              {/* </Scrollbars> */}
              {this.state.listingData && this.state.listingData.data.length > 0 ?
                <div className="pagination mt-30">
                  <PaginateElement
                    list={this.state.listingData}
                    limit={this.state.limit}
                    limitChange={(val) => {
                      this.setState(
                        {
                          limit: val,
                          page: 1
                        },
                        () =>
                          this.getData()
                      );
                    }}
                    previousPage={() => {
                      this.setState({
                        page: this.state.page - 1
                      }, () => {
                        this.getData()
                      })
                    }}
                    nextPage={() => {
                      this.setState({
                        page: this.state.page + 1
                      }, () => {
                        this.getData()
                      })
                    }}
                  />
                </div>
                : <div className="table-responsive text-center p-30 bgWhite">No Mail</div>}
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    inboxList: state.inboxReducer.inboxList
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInboxAction,
      updateFavouriteAction,
      deleteEmailAction,
      deletePermAction,
      moveToInboxAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommonListing);
