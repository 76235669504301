import React, { Component, Suspense } from "react";
import { Container, Row, Col, Badge, Button, UncontrolledPopover, PopoverBody, Input, } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { Editor } from "@tinymce/tinymce-react";
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import PageLoading from "../../../common/PageLoading";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../LeftInboxMenu";
import TagField from "../../../common/TagField";
import moment from "moment";
// import $ from "jquery";
import "./createMessageInbox.scss";

import UploadIcon from "../../../assets/img/icons/upload_white_icon.svg";
import CloseComposeMail from "../../../assets/img/icons/inbox/close_compose_mail.svg";
import AvatarPic from "../../../assets/img/icons/inbox/avatar_icon.svg";
import AddAttachment from "../../../assets/img/icons/inbox/attachemnt_icon.svg";
import RemoveFileIcon from "../../../assets/img/icons/inbox/file-close.svg";
import DownloadAttachemnet from "../../../assets/img/icons/download_attachemnet.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/inbox/mail_delete_icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/img/icons/inbox/sent_gray_icon.svg";
import { ReactComponent as HasAttachmentIcon } from "../../../assets/img/icons/has_attachment_icon.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getProfileAction,
  composeMailAction,
  getInboxMailDetailsAction,
  meetingDetailsAction
} from "../../../store/actions";
import { EMAIL_REGEX } from "../../../constant/constant";
import { checkValidFileSize } from "../../../common/commonHelper";
import { MESSAGES } from "../../../constant/messages";
import config from "../../../config.json";
import "../../Studentprofile/Studentprofile.scss";

import CustomTags from 'custom-react-tags'
import 'custom-react-tags/dist/index.css'

const baseurl = `${config.baseurl}/`;

class CreateMessage extends Component {
  constructor(props) {
    super(props);

    this.toRef = React.createRef();
    this.emailContentRef = React.createRef();

    this.state = {
      todayDate: moment().format("DD MMM YY"),
      mailToTypeCc: false,
      mailToTypeBcc: false,
      draftSave: false,
      showAddAttachment: false,
      files: [],
      thumbs: [],
      studentId: 0,
      studentEmail: "",
      loading: false,
      university: null,
      toEmails: [],
      ccEmails: [],
      bccEmails: [],
      subject: "",
      mailId: 0,
      existingFiles: [],
      existingBody: null
    };
  }

  onDrop = (filesDopped) => {
    let filesList = filesDopped;
    let noOfFiles = filesDopped.length;
    let {
      files
    } = this.state;

    for (let file = 0; file < noOfFiles; file++) {
      console.log('files[file]', filesList[file])
      let size = checkValidFileSize(filesList[file]);
      if (!size) {

        files.push(filesList[file]);
      } else {
        toast.error(MESSAGES.VALID_IMAGE_SIZE);
        return;
      }
    }

    this.setState({
      files
    });
  };

  showLoader = () => {
    this.setState({
      loading: true
    })
  }

  hideLoader = () => {
    this.setState({
      loading: false
    })
  }

  componentDidMount() {
    let param = localStorage.getItem("studentid");
    let email = localStorage.getItem("studentemail");
    let mailId = this.props.match
      ? this.props.match.params
        ? this.props.match.params.id
          ? this.props.match.params.id
          : ""
        : ""
      : "";

    let type = this.props.match
      ? this.props.match.params
        ? this.props.match.params.type
          ? this.props.match.params.type
          : ""
        : ""
      : "";

    this.setState({
      studentId: param,
      studentEmail: email,
      mailId
    }, () => {
      console.log('this.props.profile', this.props.profile, !this.props.profile)
      if (!this.props.profile) {
        // this.showLoader();
        this.props.getProfileAction({
          univ_id: this.state.studentId
        }, (response) => {
          console.log('log inresponse', response);
          this.hideLoader();
          this.setState({
            university: this.props.profile
          })
        }, (err) => {
          console.log('log inerror', err);
          this.hideLoader();
        })
      }

      if (mailId) {
        if (type == "meeting") {
          this.showLoader();
          this.props.meetingDetailsAction({
            student_id: this.state.studentId,
            meeting_id: mailId
          }, (response) => {
            this.hideLoader();
            if (response.success) {
              let toEmails = []
              let meeting = response.data
              if (meeting.host && meeting.host.email) {
                toEmails.push(meeting.host.email)
              }

              if (meeting.participants && meeting.participants.length) {
                meeting.participants.forEach(participant => {
                  if (participant.participant) {
                    toEmails.push(participant.participant.email)
                  }
                });
              }

              this.setState({
                toEmails
              })
            }
          }, (err) => {
            this.hideLoader();
          })
        } else {
          this.showLoader();
          new Promise((resolve, reject) => {
            this.props.getInboxMailDetailsAction(
              {
                student_id: this.state.studentId,
                mail_id: mailId
              },
              (response) => {
                this.hideLoader();
                if (response.success) {
                  if (
                    response.data &&
                    response.data.mail &&
                    response.data.mail.length
                  ) {
                    response.data.mail.map((val, index) => {
                      let toEmails = [],
                        ccEmails = [],
                        bccEmails = [],
                        existingFiles = [];

                      val.email_to.map((to) => {
                        if (!toEmails.includes(to.receiver.email)) {
                          toEmails.push(to.receiver.email)
                        }
                      });

                      val.email_cc.map((cc) => {
                        if (!ccEmails.includes(cc.receiver.email)) {
                          ccEmails.push(cc.receiver.email)
                        }
                      });
                      val.email_bcc.map((bcc) => {
                        if (!bccEmails.includes(bcc.receiver.email)) {
                          bccEmails.push(bcc.receiver.email)
                        }
                      });

                      val.attachments.map((file, index) => {
                        existingFiles.push(file);
                      });

                      this.setState({
                        toEmails,
                        ccEmails,
                        bccEmails,
                        existingFiles,
                        subject: val.subject,
                        existingBody: val.body
                      }, () => {
                        let mailToTypeBcc = false;
                        let mailToTypeCc = false;
                        if (this.state.ccEmails.length) {
                          mailToTypeCc = true;
                        }
                        if (this.state.bccEmails.length) {
                          mailToTypeBcc = true;
                        }
                        this.setState({
                          mailToTypeBcc,
                          mailToTypeCc
                        })
                      });
                    })
                  }
                  resolve();
                }
              },
              (err) => {
                this.hideLoader();
                reject();
              }
            )
          })
        }
      }
    })
  }

  componentDidUpdate() {
    if (
      this.props.profile
    ) {
      if (
        this.state.university !=
        this.props.profile
      ) {
        this.setState({
          university: this.props.profile
        })
      }
    }
  }

  loading = () => <PageLoading loading={true} />;

  handleInit = (evt, editor) => {
    this.setState({ length: editor.getContent({ format: "text" }).length });
  };

  sendEmail = (draft) => {
    let {
      toEmails,
      ccEmails,
      bccEmails,
      subject,
      files,
      existingFiles
    } = this.state;

    let content = this.emailContentRef.current.getContent();

    if (!draft) {
      if (!toEmails.length) {
        toast.error(MESSAGES.TO_EMAILS_REQUIRED);
        return;
      }
      if (!content) {
        toast.error(MESSAGES.EMAIL_CONTENT_REQUIRED);
        return;
      }
    }

    this.showLoader();

    let formData = new FormData();
    formData.append('student_id', this.state.studentId);
    toEmails.map((val, index) => {
      formData.append('to[]', val);
    });
    bccEmails.map((val, index) => {
      formData.append('bcc[]', val);
    });
    ccEmails.map((val, index) => {
      formData.append('cc[]', val);
    });
    formData.append('body', content);
    formData.append('subject', subject);
    if (draft) {
      formData.append('draft', 1);
    }
    if (files.length) {
      files.map((val, index) => {
        formData.append('attachment[]', val);
      })
    }
    if (this.state.mailId) {
      formData.append('mail_id', this.state.mailId);
    }
    existingFiles.map((val, index) => {
      formData.append('exists_attachments[]', val.id);
    })

    new Promise((resolve, reject) => {
      this.props.composeMailAction(
        formData,
        (response) => {
          this.hideLoader();
          if (response.success) {
            toast.success(response.message);
            if (response.data.mail_id) {
              this.setState({
                mailId: response.data.mail_id
              })
            }
            if (!draft) {
              this.props.history.push("/inbox");
            }
          }
          resolve();
        },
        (err) => {
          this.hideLoader();
          reject();
        }
      )
    })
  }

  render() {
    let {
      loading,
      toEmails,
      ccEmails,
      bccEmails
    } = this.state;

    toEmails = toEmails.map(email => ({ label: email }))
    ccEmails = ccEmails.map(email => ({ label: email }))
    bccEmails = bccEmails.map(email => ({ label: email }))

    return (
      <>
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={loading} />
          ) : null
        }
        {/* <AppHeader fixed className="foe-header-container">
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader> */}

        <div className="profileBox">
          <LeftInboxMenu title={"Compose"} />
          <Container className='profileContainer foe-inbox-container'>
            <Row>
              <Col xs="12" className="uni-right-card rightInboxSec">
                <div className="messageSection createMessage">
                  <header className="header mb-0">
                    <h3 className="title">
                      <img
                        src={CloseComposeMail}
                        alt="close"
                        className="mr-2 pointer"
                        onClick={() => {
                          if (this.state.mailId) {
                            this.props.history.push("/draftsInbox")
                          } else {
                            this.props.history.push("/inbox")
                          }
                        }}
                      />
                      Create Message
                    </h3>
                  </header>

                  <div className="mailBox">
                    <div className="mailHeader"                        >
                      <div className="topPart">
                        <div className="leftSide">
                          <span className="avatar">
                            <img src={
                              this.props.universityLogo ?
                                baseurl + this.props.universityLogo :
                                AvatarPic
                            } alt="" />
                          </span>
                          <strong className="mr-5">
                            {
                              this.state.university ? (
                                this.state.university.first_name +
                                " " + this.state.university.last_name
                              ) : null
                            }
                          </strong>
                          &lt; {
                            this.state.university ? (
                              this.state.university.addr_email
                            ) : null
                          } &gt;
                        </div>
                        <div className="rightSide">
                          <span
                            onClick={() => {
                              this.setState({
                                mailToTypeBcc: !this.state.mailToTypeBcc
                              });
                            }}
                          >
                            Bcc
                          </span>
                          <span
                            onClick={() => {
                              this.setState({
                                mailToTypeCc: !this.state.mailToTypeCc
                              });
                            }}
                          >
                            Cc
                          </span>
                          <span onClick={() => {
                            // this.setState({ draftSave: true });
                            this.sendEmail(true)
                          }}>
                            {"Save"}
                          </span>
                          <DeleteIcon
                            className="DeleteIcon" width="13px" height="16px"
                            onClick={() => {
                              this.props.history.push("/inbox")
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="content">
                      <ul className="emailAdddress">
                        <li className="show">
                          <span className="type">
                            To:
                          </span>
                          {
                            toEmails.length ? (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let toEmails = val.map(email => email.label)
                                  this.setState({ toEmails });
                                }}
                                initialValue={toEmails}
                                key="toif"
                              />
                            ) : (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let toEmails = val.map(email => email.label)
                                  this.setState({ toEmails });
                                }}
                                key="toelse"
                              />
                            )
                          }
                        </li>
                        <li
                          className={
                            this.state.mailToTypeCc === true ?
                              "show" : "hide"
                          }
                        >
                          <span className="type">
                            Cc:
                          </span>
                          {
                            ccEmails.length ? (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let ccEmails = val.map(email => email.label)
                                  this.setState({ ccEmails });
                                }}
                                initialValue={ccEmails}
                                key="ccto"
                              />
                            ) : (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let ccEmails = val.map(email => email.label)
                                  this.setState({ ccEmails });
                                }}
                                key="ccelse"
                              />
                            )
                          }
                        </li>
                        <li
                          className={
                            this.state.mailToTypeBcc === true ?
                              "show" : "hide"
                          }
                        >
                          <span className="type">
                            Bcc:
                          </span>
                          {
                            bccEmails.length ? (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let bccEmails = val.map(email => email.label)
                                  this.setState({ bccEmails });
                                }}
                                initialValue={bccEmails}
                                key="bccif"
                              />
                            ) : (
                              <CustomTags
                                name="toEmail"
                                validation={EMAIL_REGEX}
                                onChange={(val) => {
                                  // console.log("val", val);
                                  let bccEmails = val.map(email => email.label)
                                  this.setState({ bccEmails });
                                }}
                                key="bccelse"
                              />
                            )
                          }
                        </li>
                      </ul>
                      <div className="createBox">
                        <div className="subject">
                          {/* <span>Subject</span> */}
                          <Input
                            onChange={(e) => {
                              this.setState({
                                subject: e.target.value
                              })
                            }}
                            placeholder="Subject"
                            value={this.state.subject}
                          />
                        </div>
                        <Editor
                          //apiKey={tinyKey}
                          onInit={(evt, editor) =>
                            (this.emailContentRef.current = editor)
                          }
                          initialValue={this.state.existingBody}
                          init={{
                            height: 360,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists emoticons",
                            ],
                            toolbar: "undo redo | bold italic underline backcolor strikethrough superscript subscript | emoticons removeformat| formatselect | fontsizeselect lineheight fontselect | bullist numlist outdent indent alignleft aligncenter alignright alignjustify",

                            toolbar_mode: "sliding",
                            branding: false,
                            statusbar: false,

                          }}
                        />

                        {this.state.showAddAttachment &&
                          <Dropzone
                            onDrop={this.onDrop}
                            multiple
                            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                text/plain, application/pdf, image/*"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section className="dragNdrop">
                                <div
                                  {...getRootProps({
                                    className: "dropzone",
                                  })}
                                >
                                  <input
                                    {...getInputProps({
                                      //accept: "application/pdf",
                                    })}
                                    onChange={(event) => {
                                      let filesList = event.target.files;
                                      let noOfFiles = event.target.files.length;
                                      let {
                                        files
                                      } = this.state;

                                      for (let file = 0; file < noOfFiles; file++) {
                                        console.log('files[file]', filesList[file])
                                        let size = checkValidFileSize(filesList[file]);
                                        if (!size) {

                                          files.push(filesList[file]);
                                        } else {
                                          toast.error(MESSAGES.VALID_IMAGE_SIZE);
                                          return;
                                        }
                                      }

                                      this.setState({
                                        files
                                      })
                                    }}
                                  />
                                  <span className="btn btn-primary">
                                    <img
                                      src={UploadIcon}
                                      alt="upload"
                                    />{" "}
                                    Choose Files
                                  </span>
                                  <p>or drop here</p>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        }
                        {
                          this.state.files.length ||
                            this.state.existingFiles.length ? (
                            <div className="fileList">
                              <div className="detail">
                                <HasAttachmentIcon className="HasAttachmentIcon" />
                                {
                                  this.state.files.length +
                                  this.state.existingFiles.length
                                }{" "}
                                Attachment
                              </div>
                              {
                                this.state.files.map((file, index) => (
                                  <div
                                    className="box"
                                    key={file.name}
                                  >
                                    <div className="fileName">
                                      {file.name}
                                      <div className="fileSize">
                                        [{(file.size / 1024).toFixed(2)}{" "}KB]
                                      </div>
                                    </div>
                                    {/* <img
                                          src={DownloadAttachemnet}
                                          alt="Download"
                                          className="DownloadAttachemnet"
                                        /> */}

                                    <img
                                      src={RemoveFileIcon}
                                      alt="remove"
                                      className="fileRemove"
                                      onClick={() => {
                                        let {
                                          files
                                        } = this.state;

                                        files.splice(index, 1);

                                        this.setState({
                                          files
                                        });
                                      }}
                                    />
                                  </div>
                                ))
                              }
                              {
                                this.state.existingFiles.map((file, index) => (
                                  <div
                                    className="box"
                                    key={index}
                                  >
                                    <div className="fileName">
                                      {file.original_filename}
                                      <div className="fileSize">
                                        &nbsp;
                                      </div>
                                    </div>
                                    <img
                                      src={DownloadAttachemnet}
                                      alt="Download"
                                      className="DownloadAttachemnet"
                                      onClick={() => {
                                        window.open(baseurl + file.attachment_path, "_blank")
                                      }}
                                    />

                                    <img
                                      src={RemoveFileIcon}
                                      alt="remove"
                                      className="fileRemove"
                                      onClick={() => {
                                        let {
                                          existingFiles
                                        } = this.state;

                                        existingFiles.splice(index, 1);

                                        this.setState({
                                          existingFiles
                                        });
                                      }}
                                    />
                                  </div>
                                ))
                              }
                            </div>
                          ) : null
                        }
                        <footer className="d-flex align-items-center justify-space-between">
                          <img src={AddAttachment} alt="Add Attachment" className="AddAttachment"
                            onClick={() => {
                              this.setState({
                                showAddAttachment: !this.state.showAddAttachment
                              })
                            }}
                          />

                          <Button color="primary"
                            // style={{ marginLeft: '500px' }}
                            onClick={() => {
                              // this.setState({ showReply: false });
                              this.sendEmail(false)
                            }}
                          >
                            <SendIcon width="14px" />	Send
                          </Button>
                        </footer>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profileReducer.profile,
    universityLogo: state.profileReducer.universityLogo
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProfileAction,
      composeMailAction,
      getInboxMailDetailsAction,
      meetingDetailsAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateMessage);
