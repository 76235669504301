import React, { Component } from 'react';
import { Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./sidebarFixed.scss";
import { ReactComponent as DashboardIcon } from '../assets/img/square-icon.svg';
import { ReactComponent as PersonalInfoIcon } from '../assets/img/user-profile-icon.svg';
import { ReactComponent as PaymentIcon } from '../assets/img/icons/payment-method.svg';
import { ReactComponent as DocumentationIcon } from '../assets/img/documentation.svg';
import { ReactComponent as SavedSearchIcon } from '../assets/img/saved_search.svg';
import { ReactComponent as DropDownIcon } from '../assets/img/icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from '../assets/img/icons/modal_close_icon.svg';
import { ReactComponent as LoginLogIcon } from "../assets/img/icons/loginLogs.svg";
export default class ProfileDashBoard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeSidebar: false,
      submenuOpen: ""
    }
  }

  render() {
    const currentUrl = window.location.href;
    let studentProfileLink = "Viewprofile";

    return (
      <div className={this.props.menuOpen === true ? "open sidebarFixed" : "sidebarFixed"}>
        <div className="innerSidebarWrap">
          <div className='closeMenu' onClick={this.props.sidebarMenuClose}>
            <CloseIcon />
          </div>
          {/* <h3 className='title'>{this.props.title && this.props.title}</h3> */}
          <Card className="uni-left-card">
            <CardBody>
              <ListGroup className="left-list">
                <ListGroupItem className={currentUrl.includes('profiledashboard') ? 'active' : ''}>
                  <Link to="/profiledashboard">
                    <span className="iconBox"><DashboardIcon className="icon rect" style={{ width: "11px", height: "11px" }} /></span> Dashboard
                  </Link>
                </ListGroupItem>
                <ListGroupItem className={(currentUrl.includes('studentprofile') || currentUrl.includes('Viewprofile')) ? 'active' : ''}>
                  <Link to={studentProfileLink}>
                    <span className="iconBox"><PersonalInfoIcon className="icon" style={{ width: "11px", height: "11px" }} /></span> My Profile
                  </Link>
                </ListGroupItem>
                <ListGroupItem
                  className={currentUrl.includes('paymentDetails') || currentUrl.includes('pending-payments') || currentUrl.includes('payment-history') || this.state.submenuOpen === "payments" ? 'active' : ''}
                  onClick={() => { this.setState({ submenuOpen: "payments" }) }}
                >
                  <div>
                    <span className="iconBox"><PaymentIcon className="icon" style={{ position: "relative", top: "2px" }} /></span> Payments
                  </div>
                  <DropDownIcon className="optionIcon" />
                  {
                    (
                      currentUrl.includes('paymentDetails')
                      || currentUrl.includes('pending-payments')
                      || currentUrl.includes('payment-history')
                      || this.state.submenuOpen === "payments"
                    ) ? (
                      <ListGroup className="left-list submenu">
                        <ListGroupItem className={currentUrl.includes('paymentDetails') ? 'active' : ''}>
                          <Link to="/paymentDetails">Payment Details</Link>
                        </ListGroupItem>
                        <ListGroupItem className={currentUrl.includes('pending-payments') ? 'active' : ''}>
                          <Link to="/pending-payments">Pending Payments</Link>
                        </ListGroupItem>
                        <ListGroupItem className={currentUrl.includes('payment-history') ? 'active' : ''}>
                          <Link to="/payment-history">Payment History</Link>
                        </ListGroupItem>
                      </ListGroup>
                    ) : null
                  }
                </ListGroupItem>
                <ListGroupItem className={currentUrl.includes('documentation') ? 'active' : ''}>
                  <Link to="/documentation"><span className="iconBox">
                    <DocumentationIcon className="icon stroke" /></span> Documentation
                  </Link>
                </ListGroupItem>

                <ListGroupItem
                  className={
                    currentUrl.includes('savedsearch-education') || currentUrl.includes('savedsearch-preferred') || this.state.submenuOpen === "savedsearch" ? 'active' : ''
                  }
                  onClick={() => { this.setState({ submenuOpen: "savedsearch" }) }}
                >
                  <div>
                    <span className="iconBox"><SavedSearchIcon className="icon stroke" style={{ width: "11px", height: "11px" }} /></span> Saved Searches
                  </div>
                  <DropDownIcon className="optionIcon" />
                  {
                    (
                      currentUrl.includes('savedsearch-education')
                      || currentUrl.includes('savedsearch-preferred')
                      || this.state.submenuOpen === "savedsearch"
                    ) ? (
                      <ListGroup className="left-list submenu">
                        <ListGroupItem className={currentUrl.includes('savedsearch-education') ? 'active' : ''}>
                          <Link to="/savedsearch-education">Education Providers</Link>
                        </ListGroupItem>
                        <ListGroupItem className={currentUrl.includes('savedsearch-preferred') ? 'active' : ''}>
                          <Link to="/savedsearch-preferred">Preferred Partners</Link>
                        </ListGroupItem>
                      </ListGroup>
                    ) : null
                  }
                </ListGroupItem>
                <ListGroupItem className={currentUrl.includes('login-logs') ? 'active' : ''}>
                  <Link to="/login-logs"><span className="iconBox">
                    <LoginLogIcon className="icon stroke" /></span> Login Logs
                  </Link>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }

}
