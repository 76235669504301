import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import PageLoading from "../../common/PageLoading";
import DefaultHeader from "../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../Inbox/LeftInboxMenu";
import CommonList from "./components/CommonList";
import PendingMeetingData from "./PendingList.json"
import { ReactComponent as AddNewIcon } from "../../assets/img/icons/meeting/add_icon.svg";
import "../Studentprofile/Studentprofile.scss";
class DraftList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  loading = () => <PageLoading loading={true} />;
  render() {
    return (
      <>
        <ToastContainer />
        {/* <PageLoading loading={this.state.loading} /> */}
        {/* <AppHeader fixed className="foe-header-container">
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader> */}

        <div className="profileBox">
          <LeftInboxMenu title={"Meetings"} />
          <Container className='profileContainer foe-inbox-container chatList'>
            <Row>
              <Col xs="12" className="uni-right-card chatDataList meeting meetingListData">
                <CommonList
                  {...this.props}
                  type={"Drafts"}
                  handle={"draft"}
                  pathName={"/meetings-drafts"}
                  pageUrl={"/#/meetings-drafts"}
                  state={this.state}
                  meetingData={PendingMeetingData}
                  showLoading={this.showLoading}
                  hideLoading={this.hideLoading}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default DraftList;
