import React, { Component } from "react";
import { Row, Col, Button, UncontrolledPopover, PopoverBody, Popover, Input } from "reactstrap";
import "./CommonList.scss";
import MeetingDetails from "../elements/MeetingDetails";
import { Scrollbars } from "react-custom-scrollbars";

import FormeeLogo from "../../../assets/img/brand/logo_symbol.svg";
import { ReactComponent as RestoreIcon } from "../../../assets/img/icons/reset_icon.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/img/icons/keyboard_arrow_down.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/trash_empty.svg";
import { ReactComponent as StarIcon } from "../../../assets/img/star-yellow.svg";
import { ReactComponent as SearchIcon } from "../../../assets/img/search-icon.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getChatSessionListAction,
  getChatMessagesListAction,
  getProfileAction,
  deleteChatAction,
  starChatAction
} from "../../../store/actions";
import config from "../../../config.json";
import { toast } from "react-toastify";
// import { getModulePermission } from "../../../common/permissionHelper";
// import {
// 	MODULE_NAMES,
// 	MODULE_PERMISSION_NAMES,
// } from "../../../constant/permission";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

var baseurl = `${config.baseurl}`;

class CommonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptions: "Old - New",
      activeList: [],
      listData: [],
      // acceptOption: "Yes, I’ll attend",
      universityId: 0,
      page: 1,
      prevY: 0,
      loading: false,
      hasMore: true,
      details: null,
      order: "desc",
      filter: 0,
      totalCount: 0,
      currentSession: null,
      searchOpen: false,
      searchString: "",
      MeetingDetailOpen: false,
    };
  }

  componentDidMount() {
    let param = localStorage.getItem("studentid");
    this.setState({
      universityId: param,
      unReadMailCount: 0
    }, () => {
      this.getData();
      if (!this.props.profile) {
        this.getProfile()
      }
    })
  }

  getData = () => {
    let request = {
      user_id: this.state.universityId,
      user_type: "student",
      search: this.state.searchString,
      order_by: this.state.order
    };
    if (this.props.handle == "starred") {
      request['starred'] = 1;
    }
    if (this.props.handle == "deleted") {
      request['deleted'] = 1;
    }
    this.props.showLoading()
    new Promise((resolve, reject) => {
      this.props.getChatSessionListAction(request, (response) => {
        if (response.success) {
          this.props.hideLoading()
          this.setState({
            activeList: response.data.list
          }, () => {
            if (this.state.activeList.length) {
              this.getMessages(this.state.activeList[0].id)
              this.setState({
                currentSession: this.state.activeList[0]
              })
            }
          })
        }
        resolve()
      }, (err) => {
        this.props.hideLoading()
        reject()
      })
    })
  }

  getMessages = (id) => {
    this.props.showLoading()
    new Promise((resolve, reject) => {
      this.props.getChatMessagesListAction({
        id
      }, (response) => {
        this.props.hideLoading()
        if (response.success) {
          this.setState({
            listData: response.data.list
          })
        }
        resolve()
      }, (err) => {
        this.props.hideLoading()
        reject()
      })
    })
  }

  getProfile = () => {
    this.props.showLoading()
    this.props.getProfileAction({
      univ_id: this.state.universityId
    }, () => {
      this.props.hideLoading()
    }, () => {
      this.props.hideLoading()
    })
  }

  deleteRestore = (status, id) => {
    confirmAlert({
      title: '',
      message: `Are you sure you want to ${status ? 'delete' : 'restore'} this chat?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.showLoading()
            this.props.deleteChatAction({
              id,
              status
            }, (response) => {
              this.props.hideLoading()
              if (response.success) {
                this.getData()
              }
            }, (err) => {
              this.props.hideLoading()
            })
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  }

  starUnstart = (status, id) => {
    this.props.showLoading()
    this.props.starChatAction({
      id,
      status
    }, (response) => {
      this.props.hideLoading()
      if (response.success) {
        this.getData()
      }
    }, (err) => {
      this.props.hideLoading()
    })
  }

  render() {

    return (
      <Row className="row-eq-height" style={{ margin: "0" }}>
        <Col xs="12" lg="3" className="listing">
          <header>
            <div className="title">
              <h2>
                {
                  this.props.type
                }
              </h2>
              <Button color="primary" style={{ display: 'none' }}>
                <SearchIcon
                  width="15px"
                  height="15px"
                  onClick={() => {
                    this.setState({
                      searchOpen: !this.state.searchOpen
                    })
                  }}
                  id="searchChat"
                />
              </Button>
              <Popover
                placement="bottom-end"
                className="searchPopover"
                trigger="legacy"
                isOpen={this.state.searchOpen}
                toggle={() => {
                  this.setState({
                    searchOpen: false
                  })
                }}
                target="searchChat"
              >
                <PopoverBody>
                  <Row>
                    <Col
                      sm={12}
                    >
                      <Input
                        type="text"
                        placeholder="Type search keyword"
                        value={this.state.searchString}
                        onChange={(e) => {
                          this.setState({
                            searchString: e.target.value
                          })
                        }}
                      />
                      <SearchIcon
                        width="15px"
                        height="15px"
                        className="searchSubmit"
                      // onClick={() => {
                      //   this.setState({
                      //     searchOpen: false
                      //   }, this.getData)
                      // }}
                      />
                    </Col>
                  </Row>

                </PopoverBody>
              </Popover>
            </div>
            <div className="noteText"></div>
          </header>
          <div className="list">
            <Button id="filter" type="button" className="filter" color="link">
              {this.state.filterOptions} <DownArrowIcon />
            </Button>
            <UncontrolledPopover
              target="filter"
              className="filterOptions"
              placement="bottom-end"
              trigger="legacy"
            >
              <PopoverBody>
                <ul>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          filterOptions: "Old - New",
                          order: "desc",
                          page: 1,
                          filter: 0,
                        },
                        () => {
                          this.getData();
                        }
                      );
                    }}
                  >
                    Old - New
                  </li>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          filterOptions: "New - Old",
                          order: "asc",
                          page: 1,
                          filter: 0,
                        },
                        () => {
                          this.getData();
                        }
                      );
                    }}
                  >
                    New - Old
                  </li>
                </ul>
              </PopoverBody>
            </UncontrolledPopover>
            <div className="clearfix"></div>
            <Scrollbars style={{ width: "100%" }} className="listingHeight">
              <ul className="box">
                {this.state.activeList ? (
                  this.state.activeList.map((data, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          this.setState({
                            currentSession: data,
                            MeetingDetailOpen: true
                          }, () => {
                            this.getMessages(data.id)
                          });
                        }}
                        className={
                          this.state.currentSession && this.state.currentSession.id === data.id ? "active" : ""
                        }
                      >
                        <div className="head">
                          <div className="title">
                            <div
                              className="thumbnail"
                              style={{
                                backgroundImage: `url(${FormeeLogo})`,
                              }}
                            ></div>
                            {console.log("data",data)}
                            <h4>{moment(data.updated_at).format('MM-DD-YYYY')}</h4>
                          </div>
                          <div className="actionIcons">
                            {/* {
                              data.deleted_at ? (
                                <RestoreIcon 
                                  className="icon_restore" 
                                  onClick={() => {
                                    this.deleteRestore(0, data.id)
                                  }}
                                />
                              ) : (
                                <>
                                  <DeleteIcon 
                                    className="icon_delete" 
                                    onClick={() => {
                                      this.deleteRestore(1, data.id)
                                    }}
                                  />
                                  <StarIcon
                                    className={`icon_star ${
                                      data.is_starred === 1 ? "active" : ""
                                    }`}
                                    onClick={() => {
                                      if (data.starred) {
                                        this.starUnstart(0, data.id)
                                      } else {
                                        this.starUnstart(1, data.id)
                                      }
                                      
                                    }}
                                  />
                                </>
                              )
                            } */}
                          </div>
                        </div>
                        <div className="summary">{data.last_message}</div>
                      </li>
                    );
                  })
                ) : (
                  <li>No saved chats yet!</li>
                )}
              </ul>
              <div ref={(loadingRef) => (this.loadingRef = loadingRef)}></div>
            </Scrollbars>
          </div>
        </Col>
        <Col xs="12" lg="9" className={`details ${this.state.MeetingDetailOpen ? "active" : ""}`}>
          {MeetingDetails.call(this)}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    universityLogo: state.profileReducer.universityLogo,
    rolePermission: state.authReducer.rolePermission,
    profile: state.profileReducer.profile
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChatSessionListAction,
      getChatMessagesListAction,
      getProfileAction,
      deleteChatAction,
      starChatAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommonList);
