import React, { Component } from "react";
import {
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

class PaginateElement extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="paginateElement footerSection">
        <Label>Rows per page: </Label>
        <Input
          type="select"
          className="rowDataPerPage"
          id="perPageDataList"
          value={
            this.props.limit
          }
          onChange={(event) => {
            this.props.limitChange(event.target.value);
          }}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
        </Input>
        <div className="pageListInfo">
          {this.props.list
            ? this.props.list
              .current_page
            : 1}{" "}
          of{" "}
          {this.props.list
            ? this.props.list
              .last_page
            : 1}
        </div>
        <Pagination className="tablePagination">
          <PaginationItem
            disabled={
              (
                this.props.list &&
                this.props.list.prev_page_url
              ) ? false : true
            }
          >
            <PaginationLink
              previous
              
              onClick={() => {
                if (
                  this.props.list &&
                  this.props.list.prev_page_url
                ) {
                  this.props.previousPage()
                }
              }}
            />
          </PaginationItem>
          <PaginationItem
            disabled={
              (
                this.props.list &&
                this.props.list.next_page_url
              ) ? false : true
            }
          >
            <PaginationLink
              next
              
              onClick={() => {
                if (
                  this.props.list &&
                  this.props.list.next_page_url
                ) {
                  this.props.nextPage();
                }
              }}
            />
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

export default PaginateElement;
