import "../Personalinfo/Personalinfo.css";
import React, { Component, Suspense } from "react";
import { Button, Card, CardBody, Col, Container, Media, Row, FormGroup, Input, Label, } from "reactstrap";
import bargraph from "../../assets/img/university/bar_graph.svg";
import contlogo from "../../assets/img/university/australia_circle_flag.svg";
import favourites from "../../assets/img/university/favourites.svg";
import Infoicon from "../../assets/img/student/info-icon.svg";
import Closeicon from "../../assets/img/close-btn.svg";
import locateicon from "../../assets/img/university/locate-icon.svg";
import eyeicon from "../../assets/img/university/view_simple.svg";
import ratingfive from "../../assets/img/university/rating-five.svg";
import rating from "../../assets/img/university/rating.svg";
import ratingthree from "../../assets/img/university/rating-three.svg";
import ratingtwo from "../../assets/img/university/rating-two.svg";
import ratingone from "../../assets/img/university/rating-one.svg";
import backarrow from "../../assets/img/university/back_arrow.svg";
import axios from "axios";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../common/FormError";
import SideBar from "../../common/SideBar";
import PageLoading from "../../common/PageLoading";
import moment from "moment";
import { MESSAGES } from "../../constant/messages.js";
import {
  ALN_REGEX,
  CODE_REGEX,
  EMAIL_REGEX,
  MIN_MAX_REGEX,
  NAME_REGEX,
  NUMBER_REGEX,
  PAS_MAX_REG,
  ZIP_MAX_REGEX,
} from "../../constant/constant.js";
import submitForm from "../../utils/submitForm.js";
import HeaderWithLogoUserNoti from "../../containers/DefaultLayout/HeaderWithLogoUserNoti";
import "../../index.css"
import {
  AppAside,
  AppBreadcrumb2 as AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppNavbarBrand,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav2 as AppSidebarNav,
  AppSidebarToggler
} from "@coreui/react";
import { Link } from "react-router-dom";

const baseurl = `${config.baseurl}`;

const senderType = [
  {
    label: 'Parent/Guadian',
    value: '0'
  },
  {
    label: 'Student',
    value: '1'
  },
];
const grades = [
  { label: 'Engineering and Technology', value: 'engAndTech' },
  { label: 'Sciences', value: 'sciences' },
  { label: 'Arts', value: 'arts' },
  { label: 'Business, Management & Economics', value: 'businessManagement' },
  { label: 'Law, Politics, Social, Community Service & Teaching', value: 'lawPolitics' },
  { label: 'English for Academic studies', value: 'englishAcademic' },
  { label: 'Health Science, Medical, Nursing & Paramedic', value: 'healthMedical' }
]
const marritalStatuses = ["single", "married", "widowed", "separated", "divorced"];
const genders = [
  {
    label: 'Male',
    value: '1'
  },
  {
    label: 'Female',
    value: '2'
  },
  {
    label: 'Non-binary',
    value: '3'
  },
  {
    label: 'I dont want to disclose',
    value: '4'
  }
];
const ampm = [
  {
    label: 'AM',
    value: 'AM'
  },
  {
    label: 'PM',
    value: 'PM'
  },
];
const durationHour = [
  {
    label: '0',
    value: '0'
  },
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  }
];
const durationMinute = [
  {
    label: '0',
    value: '0'
  },
  {
    label: '15',
    value: '15'
  },
  {
    label: '30',
    value: '30'
  },
  {
    label: '45',
    value: '45'
  },
];
const studyCountry = [
  {
    label: 'Australia',
    value: 'Australia'
  },
  {
    label: 'Canada',
    value: 'Canada'
  },
  {
    label: 'UK',
    value: 'UK'
  },
  {
    label: 'New Zealand',
    value: 'New Zealand'
  },
  {
    label: 'USA',
    value: 'USA',
  }
];
const studyLevel = [
  {
    label: 'Primary',
    value: 'Primary'
  },
  {
    label: 'Secondary',
    value: 'Secondary'
  },
  {
    label: 'Undergraduate',
    value: 'Undergraduate'
  },
  {
    label: 'Postgraduate certificate/Diploma',
    value: 'Postgraduate certificate/Diploma'
  },
  {
    label: 'Master Degree',
    value: 'Master Degree'
  },
  {
    label: 'Doctoral Degree (Phd, M.D)',
    value: 'Doctoral Degree (Phd, M.D)',
  }
];
const studyYear = [
  {
    label: 'Don\'t Know',
    value: 'Don\'t Know'
  },
  {
    label: '2021',
    value: '2021'
  },
  {
    label: '2022',
    value: '2022'
  },
  {
    label: '2023',
    value: '2023'
  },
  {
    label: '2024',
    value: '2024'
  },
  {
    label: '2025',
    value: '2025'
  },
];
const councellingMode = [
  {
    label: 'In - Person',
    value: 'In - Person'
  },
  {
    label: 'Virtual Councelling',
    value: 'Virtual Councelling'
  },
];
var timezone2 = [
  { "label": "(GMT-12:00) International Date Line West", "value": "Etc/GMT+12" },
  { "label": "(GMT-11:00) Midway Island, Samoa", "value": "Pacific/Midway" },
  { "label": "(GMT-10:00) Hawaii", "value": "Pacific/Honolulu" },
  { "label": "(GMT-09:00) Alaska", "value": "US/Alaska" },
  { "label": "(GMT-08:00) Pacific Time (US & Canada)", "value": "America/Los_Angeles" },
  { "label": "(GMT-08:00) Tijuana, Baja California", "value": "America/Tijuana" },
  { "label": "(GMT-07:00) Arizona", "value": "US/Arizona" },
  { "label": "(GMT-07:00) Chihuahua, La Paz, Mazatlan", "value": "America/Chihuahua" },
  { "label": "(GMT-07:00) Mountain Time (US & Canada)", "value": "US/Mountain" },
  { "label": "(GMT-06:00) Central America", "value": "America/Managua" },
  { "label": "(GMT-06:00) Central Time (US & Canada)", "value": "US/Central" },
  { "label": "(GMT-06:00) Guadalajara, Mexico City, Monterrey", "value": "America/Mexico_City" },
  { "label": "(GMT-06:00) Saskatchewan", "value": "Canada/Saskatchewan" },
  { "label": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco", "value": "America/Bogota" },
  { "label": "(GMT-05:00) Eastern Time (US & Canada)", "value": "US/Eastern" },
  { "label": "(GMT-05:00) Indiana (East)", "value": "US/East-Indiana" },
  { "label": "(GMT-04:00) Atlantic Time (Canada)", "value": "Canada/Atlantic" },
  { "label": "(GMT-04:00) Caracas, La Paz", "value": "America/Caracas" },
  { "label": "(GMT-04:00) Manaus", "value": "America/Manaus" },
  { "label": "(GMT-04:00) Santiago", "value": "America/Santiago" },
  { "label": "(GMT-03:30) Newfoundland", "value": "Canada/Newfoundland" },
  { "label": "(GMT-03:00) Brasilia", "value": "America/Sao_Paulo" },
  { "label": "(GMT-03:00) Buenos Aires, Georgetown", "value": "America/Argentina/Buenos_Aires" },
  { "label": "(GMT-03:00) Greenland", "value": "America/Godthab" },
  { "label": "(GMT-03:00) Montevideo", "value": "America/Montevideo" },
  { "label": "(GMT-02:00) Mid-Atlantic", "value": "America/Noronha" },
  { "label": "(GMT-01:00) Cape Verde Is.", "value": "Atlantic/Cape_Verde" },
  { "label": "(GMT-01:00) Azores", "value": "Atlantic/Azores" },
  { "label": "(GMT+00:00) Casablanca, Monrovia, Reykjavik", "value": "Africa/Casablanca" },
  { "label": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", "value": "Etc/Greenwich" },
  { "label": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", "value": "Europe/Amsterdam" },
  { "label": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", "value": "Europe/Belgrade" },
  { "label": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris", "value": "Europe/Brussels" },
  { "label": "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb", "value": "Europe/Sarajevo" },
  { "label": "(GMT+01:00) West Central Africa", "value": "Africa/Lagos" },
  { "label": "(GMT+02:00) Amman", "value": "Asia/Amman" },
  { "label": "(GMT+02:00) Athens, Bucharest, Istanbul", "value": "Europe/Athens" },
  { "label": "(GMT+02:00) Beirut", "value": "Asia/Beirut" },
  { "label": "(GMT+02:00) Cairo", "value": "Africa/Cairo" },
  { "label": "(GMT+02:00) Harare, Pretoria", "value": "Africa/Harare" },
  { "label": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", "value": "Europe/Helsinki" },
  { "label": "(GMT+02:00) Jerusalem", "value": "Asia/Jerusalem" },
  { "label": "(GMT+02:00) Minsk", "value": "Europe/Minsk" },
  { "label": "(GMT+02:00) Windhoek", "value": "Africa/Windhoek" },
  { "label": "(GMT+03:00) Kuwait, Riyadh, Baghdad", "value": "Asia/Kuwait" },
  { "label": "(GMT+03:00) Moscow, St. Petersburg, Volgograd", "value": "Europe/Moscow" },
  { "label": "(GMT+03:00) Nairobi", "value": "Africa/Nairobi" },
  { "label": "(GMT+03:00) Tbilisi", "value": "Asia/Tbilisi" },
  { "label": "(GMT+03:30) Tehran", "value": "Asia/Tehran" },
  { "label": "(GMT+04:00) Abu Dhabi, Muscat", "value": "Asia/Muscat" },
  { "label": "(GMT+04:00) Baku", "value": "Asia/Baku" },
  { "label": "(GMT+04:00) Yerevan", "value": "Asia/Yerevan" },
  { "label": "(GMT+04:30) Kabul", "value": "Asia/Kabul" },
  { "label": "(GMT+05:00) Yekaterinburg", "value": "Asia/Yekaterinburg" },
  { "label": "(GMT+05:00) Islamabad, Karachi, Tashkent", "value": "Asia/Karachi" },
  { "label": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi", "value": "Asia/Calcutta" },
  { "label": "(GMT+05:30) Sri Jayawardenapura", "value": "Asia/Calcutta2" },
  { "label": "(GMT+05:45) Kathmandu", "value": "Asia/Katmandu" },
  { "label": "(GMT+06:00) Almaty, Novosibirsk", "value": "Asia/Almaty" },
  { "label": "(GMT+06:00) Astana, Dhaka", "value": "Asia/Dhaka" },
  { "label": "(GMT+06:30) Yangon (Rangoon)", "value": "Asia/Rangoon" },
  { "label": "(GMT+07:00) Bangkok, Hanoi, Jakarta", "value": "Asia/Bangkok" },
  { "label": "(GMT+07:00) Krasnoyarsk", "value": "Asia/Krasnoyarsk" },
  { "label": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi", "value": "Asia/Hong_Kong" },
  { "label": "(GMT+08:00) Kuala Lumpur, Singapore", "value": "Asia/Kuala_Lumpur" },
  { "label": "(GMT+08:00) Irkutsk, Ulaan Bataar", "value": "Asia/Irkutsk" },
  { "label": "(GMT+08:00) Perth", "value": "Australia/Perth" },
  { "label": "(GMT+08:00) Taipei", "value": "Asia/Taipei" },
  { "label": "(GMT+09:00) Osaka, Sapporo, Tokyo", "value": "Asia/Tokyo" },
  { "label": "(GMT+09:00) Seoul", "value": "Asia/Seoul" },
  { "label": "(GMT+09:00) Yakutsk", "value": "Asia/Yakutsk" },
  { "label": "(GMT+09:30) Adelaide", "value": "Australia/Adelaide" },
  { "label": "(GMT+09:30) Darwin", "value": "Australia/Darwin" },
  { "label": "(GMT+10:00) Brisbane", "value": "Australia/Brisbane" },
  { "label": "(GMT+10:00) Canberra, Melbourne, Sydney", "value": "Australia/Canberra" },
  { "label": "(GMT+10:00) Hobart", "value": "Australia/Hobart" },
  { "label": "(GMT+10:00) Guam, Port Moresby", "value": "Pacific/Guam" },
  { "label": "(GMT+10:00) Vladivostok", "value": "Asia/Vladivostok" },
  { "label": "(GMT+11:00) Magadan, Solomon Is., New Caledonia", "value": "Asia/Magadan" },
  { "label": "(GMT+12:00) Auckland, Wellington", "value": "Pacific/Auckland" },
  { "label": "(GMT+12:00) Fiji, Kamchatka, Marshall Is.", "value": "Pacific/Fiji" },
  { "label": "(GMT+13:00) Nuku'alofa", "value": "Pacific/Tongatapu" }
]


class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      agentId: 0,
      officeId: 0,
      country: [],
      sender_type: "",
      fname_sender: "",
      lname_sender: "",
      fname_student: "",
      lname_student: "",
      dateofbirth: "",
      flanguage: "",
      citiship: "",

      //scountry: "",
      spassport: "",
      sgender: "",
      smarital: "",

      study_country: "",
      study_level: "",
      study_year: "",
      councelling_mode: "",

      slevel: "",
      slike: "",
      items: [],
      overall_star: 0,
      agentdetails: [],
      agentdetails2: [],

      branchname: "",
      contact_address: "",

      review: "",
      likecount: "",
      rank: "",
      edusublevel: [],

      meeting_date_hour_option: [],
      allTimes2: [],

      formData: new FormData(),

      meeting_title: "",
      meeting_details: "",
      meeting_date: "",
      meeting_date_hour: "",
      meeting_date_hour_ampm: "",
      meeting_date_hour2: "",
      meeting_date_hour_ampm2: "",
      meeting_duration_hour: "",
      meeting_duration_min: "",
      meeting_date_timezone: "",

      errors: {},
      loading: false,
      timezone: []
    };
  }

  handleLoad() {
    //window.scrollTo(0, 0);
    $("html, body").animate(
      {
        scrollTop: $("#SendMessageHeader").offset().top - 170, //id of div to be scrolled
        scrollTop: $("#SendMessageHeader2").offset().top - 170, //id of div to be scrolled
      },
      1000
    );
  }


  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const goto = query.get('show');

    $(document).ready(function () {
      $(window).scrollTop(0, 0);
      $(this).scrollTop(0, 0);

      //window.scrollTo(0, 0);
    });

    $(window).one("scroll", function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    let agentId = 0
    let officeId = 0
    if (
      this.props
      && this.props.match
      && this.props.match.params
      && this.props.match.params.id
    ) {
      agentId = this.props.match.params.id
    }

    if (
      this.props
      && this.props.match
      && this.props.match.params
      && this.props.match.params.officeId
    ) {
      officeId = this.props.match.params.officeId
    }

    this.setState({
      agentId,
      officeId
    }, () => {
      this.getGeneralData();
    })
    //this.genMeetingHour();
  }

  getGeneralData = async () => {
    this.showLoader();
    const id = this.state.agentId;
    const agent_branch_id = this.state.officeId;
    //console.log(agent_branch_id);
    const agentDetailsReq = axios.get(baseurl + "/get_agentdetails/" + id + "/" + agent_branch_id);
    const countryReq = axios.get(baseurl + "/get_country");
    const agentStudentReviewsReq = axios.get(
      baseurl + "/get_agent_student_reviews_byagentid/" + id
    );
    const agentsDataReq = axios.get(
      baseurl + "/getAgentsData/" + this.state.agentId
    );
    const timezoneReq = await axios.get(
      baseurl + "/get_timezone"
    );

    //console.log("GeoAPI.Country get2: " + JSON.stringify(countryRes));

    Promise.all([agentDetailsReq, agentStudentReviewsReq, agentsDataReq, countryReq, timezoneReq])
      .then(([agentDetailsRes, agentStudentReviewsRes, agentsDataRes, countryRes, timezoneRes]) => {
        this.hideLoader();
        //console.log("agentDetailsRes.data.agent_target_branch.branch_name" + JSON.stringify(agentDetailsRes));

        console.log('log agentDetailsRes.data', agentDetailsRes.data);
        this.setState({
          agentdetails: agentDetailsRes.data
            ? agentDetailsRes.data.agentdetails
            : this.state.agentdetails,
          agentdetails2: agentDetailsRes.data
            ? agentDetailsRes.data.agent_target_branch
            : this.state.agentdetails,
          branchname: agentDetailsRes.data.agent_target_branch.branch_name,
          contact_address:
            agentDetailsRes.data.agent_target_branch.address,
          items: agentStudentReviewsRes.data.data,
          likecount: agentsDataRes.data.fav_count,
          rank: agentsDataRes.data.rank,
          country: countryRes.data,
          timezone: timezoneRes.data
        });
      })
      .catch((e) => {
        console.log('e: ', e);
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        this.hideLoader();
      });

    let x = {
      slotInterval: 30,
      openTime: '1:00',
      closeTime: '12:00',
    };

    //Format the time
    let startTime = moment(x.openTime, "HH:mm");

    //Format the end time and the next day to it
    let endTime = moment(x.openTime, "HH:mm").add(12, 'hours');

    //Times
    let allTimes = [];
    //const allTimes = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }

    this.setState({ allTimes2: allTimes });

  };

  clearAll = () => {
    /*if (param == 0) {*/
    this.setState({
      meeting_title: "",
      meeting_details: "",
      meeting_date: "",
      meeting_date_hour: "",
      meeting_date_hour_ampm: "",
      meeting_date_hour2: "",
      meeting_date_hour_ampm2: "",
      meeting_duration_hour: "",
      meeting_duration_min: "",
      meeting_date_timezone: "",

    });
    /*}*/
  };

  handleSenderTypeChange = (event) => {
    this.setState({ sender_type: event.target.value }, () => {
    });
  };

  handleTargetChange = (event, trim = false) => {
    let val = event.target.value;
    if (trim) {
      val = String(val).trim();
    }
    this.setState({ [event.target.name]: val });
  };

  handleDateChange = (date) => {
    this.setState({ meeting_date: date });
  };

  handleMeetingTitleChange = (event) => {
    this.setState({ meeting_title: event.target.value });
  };

  handleMeetingHourChange = (event) => {
    this.setState({ meeting_date_hour: event.target.value });
  };

  handleMeetingAmPmChange = (event) => {
    this.setState({ meeting_date_hour_ampm: event.target.value });
  }
  handleMeetingHourChange2 = (event) => {
    this.setState({ meeting_date_hour2: event.target.value });
  };

  handleMeetingAmPmChange2 = (event) => {
    this.setState({ meeting_date_hour_ampm2: event.target.value });
  }

  handleDurationChange = (event) => {
    this.setState({ meeting_duration_hour: event.target.value });
  };

  handleDurationMinuteChange = (event) => {
    this.setState({ meeting_duration_min: event.target.value });
  }

  handleTimeZoneChange = (event) => {
    this.setState({ meeting_date_timezone: event.target.value });
  }

  handleLayoutChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleChangeOne = (e) => {
    this.setState({
      review: e.target.value,
    });
  };

  handleSCitishipChange = (event) => {
    this.setState({ citiship: event.target.value }, () => {
    });
  };

  handleAmPmChange = (event) => {
    this.setState({ meeting_date_hour_ampm: event.target.value }, () => {
    });
  };

  handleMaritalChange = (event) => {
    this.setState({ smarital: event.target.value }, () => {
    });
  };

  handleStudyCountryChange = (event) => {
    this.setState({ study_country: event.target.value }, () => {
    });
  };

  handleStudyLevelChange = (event) => {
    this.setState({ study_level: event.target.value }, () => {
    });
  };

  handleSYearChange = (event) => {
    this.setState({ study_year: event.target.value }, () => {
    });
  };

  handleCModeChange = (event) => {
    this.setState({ councelling_mode: event.target.value }, () => {
    });
  };

  handdleSendMessage = (e) => {
    /*const student_id = localStorage.getItem("studentid");
    const agent_id = localStorage.getItem("agentid");

    //e.preventDefault();

    const products = {
      agent_id: agent_id,
      student_id: student_id,
      no_of_ratings: this.state.selectedOption,
      review_message: this.state.review,
    };

    const uri = baseurl + "/store_agent_student_reviews";

    axios.post(uri, products).then((response) => {
      this.props.history.push("/preferedpartnerabout");
      toast.success(MESSAGES.SAVED_SUCCESSFULLY);
    });*/
  };

  //Generate meeting_date_hour *************************************************************
  genMeetingHour = (e) => {
    let x = {
      slotInterval: 30,
      openTime: '1:00',
      closeTime: '12:00',
    };

    //Format the time
    let startTime = moment(x.openTime, "HH:mm");

    //Format the end time and the next day to it
    let endTime = moment(x.closeTime, "HH:mm").add(1, 'days');

    //Times
    //let allTimes = [];
    const allTimes = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      //Push times
      allTimes.push(startTime.format("HH:mm"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }

    //const meeting_date_hour_option =  allTimes;
    console.log("Meeting Hour: " + allTimes + "--" + startTime);
  }
  //Generate meeting_date_hour *************************************************************

  validate = () => {
    const errors = {};
    //const semail = this.state.semail.trim();

    const regAln = ALN_REGEX;
    const pasmax = PAS_MAX_REG;
    const zipmax = ZIP_MAX_REGEX;
    const minmax = MIN_MAX_REGEX;
    const code = CODE_REGEX;
    const regNam = NAME_REGEX;

    /*let meeting_date_time_from = this.state.meeting_date + " " + this.state.meeting_date_hour + " " + this.state.meeting_date_hour_ampm;
    let meeting_date_time_from2 = this.state.meeting_date + this.state.meeting_date_hour;
    let meeting_date_time_to = this.state.meeting_date + " " + this.state.meeting_date_hour2 + " " + this.state.meeting_date_hour_ampm2;
    let dateCompare = moment(meeting_date_time_from).isAfter('2009-12-30 12:00 am', 'year month day hour minute'); // true*/

    //let meeting_date_time_from3 = moment(meeting_date_time_from, 'DD/MM/YYYY HH:mm').format('MMM DD YYYY h:mm A');

    //console.log("Meeting From Moment: " + );
    /*console.log("Meeting From: " + meeting_date_time_from);
    console.log("Meeting To: " + meeting_date_time_to);
    console.log("dateCompare: " + dateCompare);*/

    /*var date = '23/02/2017';
    var time = '15:42';*/
    //errors.meeting_datetime_lessthan_current_datetime = "";
    //errors.meeting_datetime_to_lessthan_meeting_datetime_from = "";


    var date = this.state.meeting_date;
    var time_h = this.state.meeting_date_hour;
    var time_ampm = this.state.meeting_date_hour_ampm;
    var time_h2 = this.state.meeting_date_hour2;
    var time_ampm2 = this.state.meeting_date_hour_ampm2;

    var dateTimeNow = moment().format("DD-MM-YYYY h:mm a");
    var dateTime = moment(date, 'DD-MM-YYYY');
    var dateTime_hour = moment(time_h + time_ampm, 'h:mm a');
    var dateTime_hour_ampm = moment(time_ampm, 'a');

    var dateTime_hour2 = moment(time_h2 + time_ampm2, 'h:mm a');
    var dateTime_hour_ampm2 = moment(time_ampm2, 'a');

    var dateTime_start = dateTime.format('DD-MM-YYYY') + " " + dateTime_hour.format('h:mm a');
    var dateTime_to = dateTime.format('DD-MM-YYYY') + " " + dateTime_hour2.format('h:mm a');

    /*console.log("DateTime: " + dateTime);
    console.log("DateTime Formated: " + dateTime.format('DD-MM-YYYY') + " " + dateTime_hour.format('h:mm a'));
    console.log("DateTime Formated2: " + dateTime_start);*/
    //console.log("DateTime now: " + dateTimeNow);
    //console.log("Selected DateTime From: " + dateTime_start);
    //console.log("DateTime From: " + moment(dateTime_start, 'DD-MM-YYYY h:mm a').isAfter(moment(), 'year month day hour minute'));
    //console.log("DateTime From: " + moment(dateTime_start, 'DD-MM-YYYY h:mm a').isAfter('2021-11-22 3:00 pm', 'year month day hour minute'));

    //console.log("Selected dateTime To: " + dateTime_to);
    //console.log("dateTime To: " + moment(dateTime_to, 'DD-MM-YYYY h:mm a').isAfter(moment(dateTime_start, 'DD-MM-YYYY h:mm a'), 'year month day hour minute'));
    //console.log("dateTime To: " + moment(dateTime_to, 'DD-MM-YYYY h:mm a').isAfter('2021-11-22 3:00 pm', 'year month day hour minute'));

    //console.log(moment(`${date2} ${time2}`, 'DD-MM-YYYY h:mm a').format());
    //console.log(moment(date + time, 'LLL').format('LLL'))


    if (this.state.meeting_title === "") {
      errors.meeting_title = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.meeting_details === "") {
      errors.meeting_details = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_date").val().trim() === "") {
      errors.meeting_date = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_date_hour").val().trim() === "") {
      errors.meeting_date_hour = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_date_hour_ampm").val().trim() === "") {
      errors.meeting_date_hour_ampm = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_date_hour2").val().trim() === "") {
      errors.meeting_date_hour2 = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_date_hour_ampm2").val().trim() === "") {
      errors.meeting_date_hour_ampm2 = MESSAGES.THIS_IS_REQUIRED;
    }
    /*if ($("#meeting_duration_hour").val().trim() === "") {
      errors.meeting_duration_hour = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#meeting_duration_min").val().trim() === "") {
      errors.meeting_duration_min = MESSAGES.THIS_IS_REQUIRED;
    }*/
    if ($("#meeting_date_timezone").val().trim() === "") {
      errors.meeting_date_timezone = MESSAGES.THIS_IS_REQUIRED;
    }

    if ((this.state.meeting_date_hour !== "" && this.state.meeting_date_hour_ampm !== "")
      && moment(dateTime_start, 'DD-MM-YYYY h:mm a').isAfter(moment(), 'year month day hour minute') == false) {
      errors.meeting_datetime_lessthan_current_datetime = MESSAGES.MEETING_DATETIME_LESSTHAN;
    }

    if ((this.state.meeting_date_hour !== "" && this.state.meeting_date_hour2 !== "")
      &&
      (this.state.meeting_date_hour_ampm !== "" && this.state.meeting_date_hour_ampm2 !== "")
      &&
      (moment(dateTime_to, 'DD-MM-YYYY h:mm a').isAfter(moment(dateTime_start, 'DD-MM-YYYY h:mm a'), 'year month day hour minute') == false)) {
      errors.meeting_datetime_to_lessthan_meeting_datetime_from = MESSAGES.MEETING_DATETIME_TO_LESSTHAN_FROM;
    }
    // console.log("meeting start: " + moment(dateTime_start, 'DD-MM-YYYY h:mm a'));
    // console.log("meeting to: " + moment(dateTime_to, 'DD-MM-YYYY h:mm a'));
    // console.log("meeting start later later: " + moment(dateTime_to, 'DD-MM-YYYY h:mm a').isAfter(moment(dateTime_start, 'DD-MM-YYYY h:mm a'), 'year month day hour minute'))

    return Object.keys(errors).length === 0 ? null : errors;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors });
    //console.log("error: " + JSON.stringify(errors));
    if (errors) return;

    // this.state.formData.append("examdate", $("#examdate").val());
    this.state.formData.append("meeting_title", this.state.meeting_title);
    this.state.formData.append("meeting_details", this.state.meeting_details);
    this.state.formData.append("meeting_date", $("#meeting_date").val());
    this.state.formData.append("meeting_date_hour", this.state.meeting_date_hour);
    this.state.formData.append("meeting_date_hour_ampm", this.state.meeting_date_hour_ampm);
    this.state.formData.append("meeting_date_hour2", this.state.meeting_date_hour2);
    this.state.formData.append("meeting_date_hour_ampm2", this.state.meeting_date_hour_ampm2);
    /*this.state.formData.append("meeting_duration_hour", this.state.meeting_duration_hour);
    this.state.formData.append("meeting_duration_min", this.state.meeting_duration_min);*/
    this.state.formData.append("meeting_date_timezone", this.state.meeting_date_timezone);

    //this.state.formData.append("student_id", 1);
    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("agent_id", this.state.agentId);
    console.log('log in send');
    this.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        this.hideLoader();
        toast.success(msg.message);
        this.props.history.push(`/preferedpartnerabout/${this.state.agentId}?show=contact`);
        //console.log("this.state.formData: " + this.state.formData);
        this.setState({ errors: {} });
        this.clearAll();
        /*setTimeout(function () {
          $("#tab2").addClass("active");
          $("#tab1").removeClass("active");
          $(".generaltab").removeClass("active");
          $(".educationtab").addClass("active");
        }, 3000);*/
      },
      "store-request-meeting"
    );
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  hideLoader = () => {
    this.setState({ loading: false });
  };

  render() {
    const handleKeyDownEvent = (e) => (
      e.keyCode === 69 ||
      e.keyCode === 190 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189
    ) && e.preventDefault()

    const prods = this.state.edusublevel.map((ub, index) => {
      if (this.state.higheduleveltype == "sublevel") {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            key={sublevel.id}
            value={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );
        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      } else if (this.state.higheduleveltype == "mainlevel") {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            key={sublevel.id}
            value={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );

        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      } else {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            value={sublevel.id}
            key={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );
        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      }
    });

    const imgpath = `${config.baseurl}`;
    let overall_star = 0;

    this.state.items.forEach((item, index) => {
      overall_star = overall_star + item.no_of_ratings;
    });
    const overall_review = overall_star / this.state.items.length;

    {/*<div className="animated fadeIn gray-bg-300 foe-viewus">
        <form className="was-validated" onSubmit={this.handleSubmit}>
          <div className="container-fluid px-0">
            <div className="top-section">
              <div className="container mb-5">

                 <div className="col-md-10 col-lg-8 col-12 leave-review mx-auto">
                    <a
                      className="col-6 backarrow mt-5 pl-md-5 pt-3"
                      href="/#/preferedpartnerabout?show=contact"
                    >
                      <span className="pr-1">
                        <img src={backarrow} alt="" />
                      </span>
                      Back {localStorage.getItem("ContactUsBranchID")}
                    </a>
                  </div>*/}

    return (
      <div id="SendMessageHeader" className="foe-pp-container sendMessageSec">
        {/* <AppHeader fixed className="foe-header-container headerWithLogoUserNoti">
          <Suspense>
            <HeaderWithLogoUserNoti />
          </Suspense>
        </AppHeader> */}

        <div className="foe-student-box">
          {this.state.loading && <PageLoading />}
          <Container>
            <Row>

              <Col xs="12" sm="12" xl="12" md="12" lg="12">
                <Card className="uni-right-card">
                  <CardBody>
                    <div className="row col-12 pr-0">
                      <div className="col-md-12 col-lg-12 col-xl-12 d-flex justify-content-start">

                        <div className="personalinfo">
                          <div className="row">
                            <div className="col-md-2 per-box py-4 valigntop">
                              <Link
                                className=" backarrow mt-0 pl-md-5 pt-1 ml-5"
                                to={`/preferedpartnerabout/${this.state.agentId}`}
                              >
                                <span className="pr-1">
                                  <img src={backarrow} alt="" />
                                </span>
                                Back
                                {/*{localStorage.getItem("ContactUsBranchID")}*/}
                              </Link>
                            </div>
                            <div className="col-md-10 per-box py-4">
                              <div id="SendMessageHeader2"
                                className="destination-box row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                                <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-header">
                                        Meeting Request
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-name">
                                        {/*{console.log(JSON.stringify(this.state.agentdetails2))}*/}
                                        {this.state.agentdetails.company_name},  {this.state.agentdetails2.branch_name}
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-address">
                                        <img
                                          src={locateicon}
                                          className="ag-icon mr-2"
                                        />
                                        {this.state.contact_address}
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-address">
                                        <hr className="uni-label-pp-hr3" />
                                      </Label>
                                    </FormGroup>
                                  </div>

                                  {/*<div className="personal-box-inner row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0">

                                                            <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">*/}
                                  <div className="col-md-12 mt-3 ml-0 mb-2">
                                    <table size="100%" height="0px" border="0" className="">
                                      <tr>
                                        <td width="17%" className="sendmessage-title">
                                          Title <span style={{ color: 'red' }}>*</span>
                                        </td>
                                        <td width="83%">
                                          <Input
                                            type="text"
                                            id="meeting_title"
                                            onChange={this.handleMeetingTitleChange}
                                            className="col-md-12 "
                                            placeholder="..."
                                            value={this.state.meeting_title}
                                            name="meeting_title"
                                          >
                                          </Input>
                                          {/*{this.state.errors.meeting_title &&
                                                                            <FormError error={this.state.errors.meeting_title} className="error"/>}*/}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td height="20px"></td>
                                        <td className="">
                                          {this.state.errors && this.state.errors.meeting_title ?
                                            (<> <FormError error={this.state.errors.meeting_title} className="error" /> <br /> </>)
                                            : null}
                                          {/*{console.log("Meeting Title: " + this.state.errors.meeting_title)}*/}
                                        </td>
                                      </tr>

                                      <tr className="mt-2">
                                        <td className="sendmessage-title align-top sendmessage-row"><br />
                                          Details <span style={{ color: 'red' }}>*</span>
                                        </td>
                                        <td>
                                          <textarea
                                            id="meeting_details"
                                            value={this.state.meeting_details}
                                            className="form-control form-control4 "
                                            placeholder="..."
                                            onChange={this.handleTargetChange}
                                            name="meeting_details"
                                          />
                                          {/*{this.state.errors.meeting_details &&
                                                                            <FormError error={this.state.errors.meeting_details} className="error"/>}*/}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td height="20px"></td>
                                        <td className="">
                                          {this.state.errors && this.state.errors.meeting_details ?
                                            (<> <FormError error={this.state.errors.meeting_details} className="error" /> <br /> </>)
                                            : null}
                                          {/*{console.log("Meeting Title: " + this.state.errors.meeting_title)}*/}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="sendmessage-title">
                                          Date <span style={{ color: 'red' }}>*</span>
                                        </td>
                                        <td>
                                          <DatePicker
                                            selected={this.state.meeting_date}
                                            onChange={this.handleDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control col-md-4 calendar-icon2"
                                            id="meeting_date"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="YYYY-MM-DD"
                                            minDate={new Date()}
                                            name="meeting_date"
                                          />
                                          {/*{this.state.errors.meeting_date &&
                                                                          <FormError error={this.state.errors.meeting_date} className="error"/>}*/}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td height="20px"></td>
                                        <td className="">
                                          {this.state.errors && this.state.errors.meeting_date ?
                                            (<> <FormError error={this.state.errors.meeting_date} className="error" /> <br /> </>)
                                            : null}
                                          {this.state.errors && this.state.errors.meeting_datetime_lessthan_current_datetime ?
                                            (<> <FormError error={this.state.errors.meeting_datetime_lessthan_current_datetime} className="error" /> <br /> </>)
                                            : null}
                                          {this.state.errors && this.state.errors.meeting_datetime_to_lessthan_meeting_datetime_from ?
                                            (<> <FormError error={this.state.errors.meeting_datetime_to_lessthan_meeting_datetime_from} className="error" /> <br /> </>)
                                            : null}
                                          {/*{console.log("Meeting Title: " + this.state.errors.meeting_title)}*/}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="sendmessage-title align-top sendmessage-row"><br />
                                          Time <span style={{ color: 'red' }}>*</span>
                                        </td>
                                        <td>
                                          <table size="100%" height="0px" border="0" className="w-100">
                                            <tr>
                                              <td width="100%" className="sendmessage-title">
                                                <div className="">

                                                  <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                                    <div className="col-md-6 mt-0 mb-0">
                                                      <FormGroup>
                                                        {/*<Label className="uni-label3 font-raleway mb-2">
                                                                                            <span style={{color: 'white'}}>*</span>
                                                                                        </Label>*/}
                                                        <div className="uni-label3 input-group" >
                                                          <Input
                                                            type="select"
                                                            id="meeting_date_hour"
                                                            onChange={this.handleMeetingHourChange}
                                                            className="col-md-7"
                                                            value={this.state.meeting_date_hour}
                                                          >
                                                            <option value="">...</option>
                                                            {this.state.allTimes2.map((meetingHour) =>
                                                              <option
                                                                key={meetingHour}
                                                                value={meetingHour}
                                                              >
                                                                {meetingHour}
                                                              </option>
                                                            )}
                                                          </Input>&nbsp;&nbsp;&nbsp;
                                                          <Input
                                                            type="select"
                                                            id="meeting_date_hour_ampm"
                                                            onChange={this.handleMeetingAmPmChange}
                                                            className="col-md-6"
                                                            value={this.state.meeting_date_hour_ampm}
                                                          >
                                                            <option value="">...</option>
                                                            {ampm.map((ampm) =>
                                                              <option
                                                                key={ampm.value}
                                                                value={ampm.value}
                                                              >
                                                                {ampm.label}
                                                              </option>
                                                            )}
                                                          </Input>
                                                          <label className="align-left4">to</label>
                                                        </div>
                                                        {/*<div><span className="align-left3"> to </span></div>*/}
                                                      </FormGroup>

                                                      {this.state.errors && this.state.errors.meeting_date_hour &&
                                                        <FormError error={this.state.errors.meeting_date_hour} className="error error-align1" />}
                                                      {this.state.errors && this.state.errors.meeting_date_hour_ampm &&
                                                        <FormError error={this.state.errors.meeting_date_hour_ampm} className="error error-align2" />}
                                                    </div>
                                                    <div className="col-md-5 mt-0 mb-0">
                                                      <FormGroup>
                                                        {/*<Label className="uni-label3 font-raleway mb-2">
                                                                                              Duration <span style={{color: 'red'}}>*</span>
                                                                                          </Label>*/}
                                                        <div className="uni-label3 input-group align-left1">
                                                          <Input
                                                            type="select"
                                                            id="meeting_date_hour2"
                                                            onChange={this.handleMeetingHourChange2}
                                                            className="col-md-7"
                                                            value={this.state.meeting_date_hour2}
                                                          >
                                                            <option value="">...</option>
                                                            {this.state.allTimes2.map((meetingHour) =>
                                                              <option
                                                                key={meetingHour}
                                                                value={meetingHour}
                                                              >
                                                                {meetingHour}
                                                              </option>
                                                            )}
                                                          </Input>&nbsp;&nbsp;&nbsp;
                                                          <Input
                                                            type="select"
                                                            id="meeting_date_hour_ampm2"
                                                            onChange={this.handleMeetingAmPmChange2}
                                                            className="col-md-5"
                                                            value={this.state.meeting_date_hour_ampm2}
                                                          >
                                                            <option value="">...</option>
                                                            {ampm.map((ampm) =>
                                                              <option
                                                                key={ampm.value}
                                                                value={ampm.value}
                                                              >
                                                                {ampm.label}
                                                              </option>
                                                            )}
                                                          </Input>
                                                        </div>
                                                      </FormGroup>
                                                      {this.state.errors && this.state.errors.meeting_date_hour2 &&
                                                        <FormError error={this.state.errors.meeting_date_hour2} className="error error-align3" />}
                                                      {this.state.errors && this.state.errors.meeting_date_hour_ampm2 &&
                                                        <FormError error={this.state.errors.meeting_date_hour_ampm2} className="error error-align4" />}
                                                      {/*{this.state.errors.dateofbirth &&
                                                                                        <FormError error={this.state.errors.dateofbirth} className="error"/>}*/}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td width="100%" className="sendmessage-title">
                                              </td>
                                            </tr>




                                          </table>
                                          {/*<Input
                                                                              type="select"
                                                                              id="meeting_date_hour"
                                                                              onChange={this.handleMeetingHourChange}
                                                                              className="w-25"
                                                                              value={this.state.meeting_date_hour}
                                                                          >
                                                                              <option value="">Select..</option>
                                                                                {this.state.allTimes2.map((meetingHour) =>
                                                                                    <option
                                                                                        key={meetingHour}
                                                                                        value={meetingHour}
                                                                                    >
                                                                                        {meetingHour}
                                                                                    </option>
                                                                                )}
                                                                          </Input>&nbsp;&nbsp;&nbsp;
                                                                          <Input
                                                                              type="select"
                                                                              id="meeting_date_hour_ampm"
                                                                              onChange={this.handleMeetingAmPmChange}
                                                                              className="w-25"
                                                                              value={this.state.meeting_date_hour_ampm}
                                                                          >
                                                                              <option value="">Select..</option>
                                                                                {ampm.map((ampm) =>
                                                                                    <option
                                                                                        key={ampm.value}
                                                                                        value={ampm.value}
                                                                                    >
                                                                                        {ampm.label}
                                                                                    </option>
                                                                                )}
                                                                          </Input>*/}

                                        </td>
                                      </tr>

                                      <tr>
                                        <td height="10px"></td>
                                        <td className="">
                                          {/*{this.state.errors.meeting_date ?
                                                                            (<> <FormError error={this.state.errors.meeting_date} className="error"/> <br/> </>)
                                                                          : null }*/}
                                          {/*{console.log("Meeting Title: " + this.state.errors.meeting_title)}*/}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="sendmessage-title">
                                          Time Zone <span style={{ color: 'red' }}>*</span>
                                        </td>
                                        <td>
                                          <Input
                                            type="select"
                                            id="meeting_date_timezone"
                                            onChange={this.handleTimeZoneChange}
                                            className="col-md-12"
                                            value={this.state.meeting_date_timezone}
                                          >
                                            <option value="">...</option>
                                            {this.state.timezone && this.state.timezone.map((timezone) =>
                                              <option
                                                key={timezone.id}
                                                value={timezone.id}
                                              >
                                                {timezone.label}
                                              </option>
                                            )}
                                          </Input>
                                          {/*{this.state.errors.meeting_date_timezone &&
                                                                          <FormError error={this.state.errors.meeting_date_timezone} className="error"/>}*/}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td height="20px"></td>
                                        <td className="">
                                          {this.state.errors && this.state.errors.meeting_date_timezone ?
                                            (<> <FormError error={this.state.errors.meeting_date_timezone} className="error" /> <br /> </>)
                                            : null}
                                          {/*{console.log("Meeting Title: " + this.state.errors.meeting_title)}*/}
                                        </td>
                                      </tr>


                                    </table>
                                  </div>


                                  {/*<div className="col-md-12 mt-0 mb-0">
                                                                    <FormGroup>
                                                                        <Label className="uni-label3 font-raleway mb-2">
                                                                           Tell us what you need :
                                                                            <span style={{color: 'red'}}></span>
                                                                        </Label>
                                                                        <Label className="uni-label3">
                                                                          <textarea
                                                                            id="message_details"
                                                                            value={this.state.message_details}
                                                                            className="form-control  form-control3 "
                                                                            placeholder="Type here"
                                                                            onChange={this.handleTargetChange}
                                                                            name="message_details"
                                                                          />
                                                                        </Label>
                                                                    </FormGroup>
                                                                    {this.state.errors.message_details &&
                                                                    <FormError error={this.state.errors.message_details} className="error"/>}
                                                                </div>
                                                                <div className="col-md-6 mt-3 mb-0">
                                                                    <FormGroup>
                                                                        <Label className="uni-label3 font-raleway mb-2">
                                                                            When <span style={{color: 'red'}}>*</span>
                                                                        </Label>
                                                                        <div className="uni-label3">
                                                                            <DatePicker
                                                                                selected={this.state.meeting_date}
                                                                                onChange={this.handleDateChange}
                                                                                dateFormat="yyyy-MM-dd"
                                                                                className="form-control calendar-icon"
                                                                                id="meeting_date"
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                placeholderText="YYYY-MM-DD"
                                                                                minDate={new Date()}
                                                                                name="meeting_date"
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    {this.state.errors.meeting_date &&
                                                                    <FormError error={this.state.errors.meeting_date} className="error"/>}
                                                                </div>*/}

                                  {/*<div className="col-md-6 mt-3 mb-0">
                                                                    <FormGroup>
                                                                        <Label className="uni-label3 ">
                                                                            Time Zone <span style={{color: 'red'}}>*</span>
                                                                        </Label>
                                                                            <div className="uni-label3 font-raleway mb-2">
                                                                              <Input
                                                                              type="select"
                                                                              id="meeting_date_timezone"
                                                                              onChange={this.handleTimeZoneChange}
                                                                              className="w-100"
                                                                              value={this.state.meeting_date_timezone}
                                                                          >
                                                                              <option value="">Select..</option>
                                                                                {timeZone.map((timezone) =>
                                                                                    <option
                                                                                        key={timezone.value}
                                                                                        value={timezone.value}
                                                                                    >
                                                                                        {timezone.label}
                                                                                    </option>
                                                                                )}
                                                                          </Input>
                                                                          </div>
                                                                    </FormGroup>
                                                                    {this.state.errors.meeting_date_timezone &&
                                                                    <FormError error={this.state.errors.meeting_date_timezone} className="error"/>}
                                                                </div>*/}

                                  {/*</div>
                                                        </div>*/}

                                </div>
                              </div>

                            </div>
                            <hr className="uni-label-pp-hr2" />
                          </div>
                          <div className="align-center pb-4 ml-0 sendMessage mt-4 vertical-center">
                            <a
                              className="font-11 btn-primary3 btn-primary3-font inti-btn active mt-3 black-outline box-shadow-high hand-cursor"
                              /*onClick={this.handdleSendMessage()}*/
                              onClick={this.onSubmit.bind(this)}
                            >
                              SEND
                            </a>
                          </div>
                          {/* <div className="align-center pb-4 ml-0 sendMessage mt-1 vertical-center">
                            <Link
                              className="delete-request hand-cursor"
                              to={`/preferedpartnerabout/${this.state.agentId}`}
                            >
                              Delete my request
                            </Link>
                          </div> */}
                        </div>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    );
  }
}

export default SendMessage;
