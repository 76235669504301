import React, { Component } from "react";
import { Row, Col, Badge, Button, UncontrolledPopover, PopoverBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label } from "reactstrap";
import "./CommonList.scss";
import MeetingDetails from "../elements/MeetingDetails"
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';

import { ReactComponent as AddNewIcon } from "../../../assets/img/icons/meeting/add_icon.svg";
import { ReactComponent as DownArrowIcon } from "../../../assets/img/icons/keyboard_arrow_down.svg";
import { ReactComponent as BlueTick } from "../../../assets/img/icons/tick_icon_blue.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/img/icons/inbox/mail_delete_icon.svg";
import { ReactComponent as LinkIcon } from "../../../assets/img/icons/link_icon.svg";
import { ReactComponent as BackArrow } from "../../../assets/img/icons/back-arrow.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  meetingListAction,
  meetingDetailsAction,
  getProfileAction,
  deleteMeetingAction,
  updateMeetingStatusAction,
  sendMeetingAction
} from "../../../store/actions";
import config from "../../../config.json";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import PendingListData from "../PendingList1.json"
import AvatarPic from "../../../assets/img/icons/inbox/avatar_icon.svg";

var baseurl = `${config.baseurl}`;

class CommonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptions: "New - Old",
      activeList: 0,
      // listData: PendingListData,
      listData: [],
      acceptOption: "Yes, I’ll attend",
      studentId: 0,
      page: 1,
      prevY: 0,
      loading: false,
      hasMore: true,
      // details: PendingListData[0],
      details: null,
      order: "desc",
      filter: 0,
      totalCount: 0,
      MeetingDetailOpen: false,
    };

  }

  componentDidMount() {
    let studentId = localStorage.getItem("studentid");

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);

    this.setState({
      studentId,
      filterOptions: this.props.handle == "upcoming" ?
        "New - Old" : "New - Old"
    }, () => {
      this.getMeetingList();
      this.props.getProfileAction(
        {
          univ_id: this.state.studentId
        },
        () => { },
        () => { }
      )
    })
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      console.log('calllced here', y, this.state.prevY)
      if (
        !this.state.loading &&
        this.state.hasMore
      ) {
        this.getMeetingList();
      }
    }
    this.setState({ prevY: y });
  }

  getMeetingList = () => {
    this.props.showLoading();
    let requestObj = {
      handle: this.props.handle,
      student_id: this.state.studentId,
      order: this.state.order,
      page: this.state.page
    };
    if (this.state.filter) {
      requestObj.filter = this.state.filter
    }
    new Promise((resolve, reject) => {
      this.props.meetingListAction(
        requestObj,
        (response) => {
          this.props.hideLoading()
          this.setState({
            loading: false
          })
          if (response.success) {
            if (!response.data.next_page_url) {
              this.setState({
                hasMore: false
              })
            } else {
              this.setState({
                page: this.state.page + 1
              })
            }
            if (response.data.data) {
              let {
                listData
              } = this.state;
              if (this.state.page == 1) {
                listData = [];
              }
              response.data.data.map((val) => {
                listData.push(val)
              })
              this.setState({
                listData,
                totalCount: response.data.total
              }, () => {
                if (!this.state.details) {
                  // console.log('listData', listData)
                  if (listData.length) {
                    this.getDetails(listData[0].id)
                    this.setState({
                      activeList: listData[0].id
                    })
                  }
                }
              })
            }
          }
          resolve();
        },
        (err) => {
          this.props.hideLoading()
          reject()
        }
      )
    })
  }

  getDetails = (id) => {
    this.props.showLoading()
    new Promise((resolve, reject) => {
      this.props.meetingDetailsAction(
        {
          student_id: this.state.studentId,
          meeting_id: id
        },
        (response) => {
          this.props.hideLoading();
          if (response.success) {
            this.setState({
              details: response.data
            })
          }
          resolve();
        },
        (err) => {
          this.props.hideLoading();
          reject();
        }
      )
    })
  }

  updateStatus = (status) => {
    this.props.showLoading();
    new Promise((resolve, reject) => {
      this.props.updateMeetingStatusAction(
        {
          university_id: this.state.studentId,
          meeting_id: this.state.details.id,
          status: status
        },
        (response) => {
          this.props.hideLoading();
          if (response.success) {
            toast.success(response.message);
            this.setState({
              details: null
            }, () => {
              this.getMeetingList()
            })
          }
          resolve();
        },
        (err) => {
          this.props.hideLoading();
          reject();
        }
      )
    })
  }

  getCurrentStatusString = (details) => {
    // this.state.acceptOption
    let returnString = "Yes, I’ll attend";
    if (details.participants) {
      let participant = null;
      details.participants.map(
        (val) => {
          if (
            val.participant_id == this.state.studentId
          ) {
            participant = val
          }
        }
      );
      if (participant) {
        if (participant.status == 3) {
          returnString = "Maybe";
        }
        if (participant.status == 4) {
          returnString = "No";
        }
      }
    }

    return returnString;
  }

  sendMeeting = () => {
    this.props.showLoading()
    this.props.sendMeetingAction({
      student_id: this.state.studentId,
      meeting_id: this.state.details.id
    }, (response) => {
      this.props.hideLoading()
      if (response.success) {
        this.setState({
          details: null
        })
        this.getMeetingList()
      }
    }, (err) => {
      this.props.hideLoading()
    })
  }

  render() {

    //console.log("details", details);

    return (
      <Row className="row-eq-height" style={{ margin: "0" }}>
        <Col xs="12" lg="3" className="listing">
          {/* {console.log(PendingListData, "PendingListData")} */}
          <header>
            <h2>
              {this.props.type}
              <Badge>{this.state.totalCount}</Badge>
            </h2>
            <Button color="primary"
              onClick={() => {
                localStorage.setItem('CreateOrEditMeeting', "Create New Meeting");
                this.props.history.push("/create-meeting");
              }}
            ><AddNewIcon width="15px" height="15px" /></Button>
          </header>
          <div className="list">
            <Button id="filter" type="button" className="filter" color="link">
              {this.state.filterOptions} <DownArrowIcon />
            </Button>
            <UncontrolledPopover target="filter" className="filterOptions" placement="bottom-end" trigger="legacy">
              <PopoverBody>
                {/* {
                  this.props.handle == "upcoming" ? (
                    <ul>
                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "Show All responses",
                            order: "desc",
                            page: 1,
                            filter: 0
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        Show All responses
                      </li>

                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "Yes, I will attend",
                            order: "desc",
                            page: 1,
                            filter: 2
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        Yes, I will attend
                      </li>
                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "Maybe",
                            order: "desc",
                            page: 1,
                            filter: 3
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        Maybe
                      </li>

                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "No",
                            order: "desc",
                            page: 1,
                            filter: 4
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        No
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "New - Old",
                            order: "desc",
                            page: 1,
                            filter: 0
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        New - Old
                      </li>
                      <li
                        onClick={() => {
                          this.setState({
                            filterOptions: "Old - New",
                            order: "asc",
                            page: 1,
                            filter: 0
                          }, () => {
                            this.getMeetingList()
                          })
                        }}
                      >
                        Old - New
                      </li>
                    </ul>
                  )
                } */}
                {
                  <ul>
                    <li
                      onClick={() => {
                        this.setState({
                          filterOptions: "New - Old",
                          order: "desc",
                          page: 1,
                          filter: 0
                        }, () => {
                          this.getMeetingList()
                        })
                      }}
                    >
                      New - Old
                    </li>
                    <li
                      onClick={() => {
                        this.setState({
                          filterOptions: "Old - New",
                          order: "asc",
                          page: 1,
                          filter: 0
                        }, () => {
                          this.getMeetingList()
                        })
                      }}
                    >
                      Old - New
                    </li>
                  </ul>
                }
              </PopoverBody>
            </UncontrolledPopover>
            <div className="clearfix"></div>
            <Scrollbars style={{ height: "calc(100vh - 270px)" }} className="listingHeight">
              <ul className="box">
                {this.state.listData &&
                  this.state.listData.length ? (
                  this.state.listData.map((data, index) => {
                    return (
                      <li key={index}
                        onClick={() => {
                          if (data.id != this.state.activeList) {
                            this.getDetails(data.id)
                            this.setState({
                              activeList: data.id,
                              MeetingDetailOpen: true
                            })
                          }
                        }}
                        className={
                          this.state.activeList === data.id ?
                            "active" : ""
                        }
                      >
                        <div className="head">
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${data.host_id == this.state.studentId ?
                                (this.props.universityLogo ? baseurl + this.props.universityLogo : AvatarPic) :
                                (data.host.logo ?
                                  baseurl + data.host.logo :
                                  AvatarPic)
                                })`
                            }}
                          >
                          </div>
                          <h4>
                            {
                              data.host_id == this.state.studentId ?
                                "Me" : (
                                  data.host.personal_details ? (
                                    data.host.personal_details.first_name +
                                    " " +
                                    data.host.personal_details.last_name
                                  ) : data.host.first_name +
                                  " " +
                                  data.host.last_name
                                )
                            }
                            <span>{data.meeting_left_time}</span>
                          </h4>
                        </div>
                        <strong className="dateTime">{moment(data.meeting_date).format("ddd DD MMM")} at {data.meeting_start_time_val}-{data.meeting_end_time}</strong>
                        <div className="summary">{data.title}</div>
                        <span className="participants">{data.participants_count} Participants</span>
                      </li>
                    )
                  })
                ) : (
                  <li>
                    No meetings yet!
                  </li>
                )
                }
              </ul>
              <div
                ref={loadingRef => (this.loadingRef = loadingRef)}
              >

              </div>
            </Scrollbars>
          </div>
        </Col>
        <Col xs="12" lg="9" className={`details ${this.state.MeetingDetailOpen ? "active" : ""}`}>
          {
            this.state.details ? (
              <>
                <div className="head">
                  <div className="backArrowWrap">
                    <div className="backBtn d-lg-none" display="md" mobile onClick={() => { this.setState({ MeetingDetailOpen: false }) }}>
                      <BackArrow />
                    </div>
                    <h3>
                      <span className="thumbnail" style={{
                        backgroundImage: `url(${this.state.details.host_id == this.state.studentId ?
                          (this.props.universityLogo ? baseurl + this.props.universityLogo : AvatarPic) :
                          (this.state.details.host.logo ?
                            baseurl + this.state.details.host.logo :
                            AvatarPic)})`
                      }}>

                      </span>
                      {
                        this.state.details ?
                          (
                            this.state.details.host_id == this.state.studentId ?
                              "Me" :
                              (
                                this.state.details.host.first_name +
                                " " +
                                this.state.details.host.last_name
                              )
                          ) :
                          ""
                      }
                    </h3>
                  </div>
                  <DeleteIcon
                    onClick={() => {
                      confirmAlert({
                        title: "Confirm",
                        message: "Are you sure you want to delete this event?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: () => {
                              this.props.showLoading();
                              new Promise((resolve, reject) => {
                                this.props.deleteMeetingAction(
                                  {
                                    student_id: this.state.studentId,
                                    meeting_id: this.state.details.id
                                  },
                                  (response) => {
                                    this.props.hideLoading();
                                    if (response.success) {
                                      toast.success(response.message);
                                      this.setState({
                                        details: null
                                      }, () => {
                                        this.getMeetingList()
                                      })
                                    }
                                    resolve();
                                  },
                                  (err) => {
                                    this.props.hideLoading();
                                    reject();
                                  }
                                )
                              })
                            },
                          },
                          {
                            label: "No",
                            onClick: () => {

                            },
                          },
                        ],
                      });
                    }}
                  />
                </div>
                <div className="content">
                  <MeetingDetails
                    {...this.props}
                    details={this.state.details}
                    state={this.state}
                  />
                  <div className="description">
                    {
                      this.state.details.host_id != this.state.studentId ? (
                        <section>
                          <strong className="title">Accept Invite</strong>
                          <UncontrolledDropdown className="acceptInvite">
                            <DropdownToggle color="primary">
                              {
                                this.getCurrentStatusString(this.state.details)
                              } <DownArrowIcon />
                            </DropdownToggle>
                            <DropdownMenu className="options">
                              <DropdownItem
                                onClick={() => {
                                  this.updateStatus('yes')
                                }}
                              ><span><BlueTick /></span> Yes, I’ll attend</DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  this.updateStatus('maybe')
                                }}
                              ><span><BlueTick /></span> Maybe</DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  this.updateStatus('no')
                                }}
                              ><span><BlueTick /></span> No</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </section>
                      ) : null
                    }
                  </div>

                  {this.props.type === "Upcoming" && (this.state.details.meeting_link || this.state.details.participant_link) &&
                    <div className="description">
                      <section>
                        <hr />
                        <h3 className="title">
                          <LinkIcon /> {
                            this.state.details.host_id ==
                              this.state.studentId ?
                              "Start meeting here" :
                              "Join meeting here"
                          }
                        </h3>
                        <Row className="copyLink">
                          <Col xs="12" md="9">
                            <a
                              href={
                                this.state.details.host_id ==
                                  this.state.studentId ?
                                  this.state.details.meeting_link :
                                  this.state.details.participant_link
                              }
                              target={"_blank"}
                            >
                              {
                                this.state.details.host_id ==
                                  this.state.studentId ?
                                  this.state.details.meeting_link :
                                  this.state.details.participant_link
                              }
                              </a>
                          </Col>
                          <Col xs="12" md="3" className="p-0">
                            {
                              this.state.details.host_id ==
                                this.state.studentId ? (
                                <>
                                  <Button
                                    color="link"
                                    onClick={() => {
                                      if (typeof (navigator.clipboard) == 'undefined') {
                                        var textArea = document.createElement("textarea");
                                        textArea.value = this.state.details.meeting_link;
                                        textArea.style.position = "fixed";  //avoid scrolling to bottom
                                        document.body.appendChild(textArea);
                                        textArea.focus();
                                        textArea.select();

                                        try {
                                          var successful = document.execCommand('copy');
                                          var msg = successful ? 'successful' : 'unsuccessful';
                                          toast.success('Link copied successfully!')
                                        } catch (err) {
                                          toast.warning('Was not possible to copy te text:', err);
                                        }

                                        document.body.removeChild(textArea)
                                        return;
                                      } else {
                                        navigator.clipboard.writeText(this.state.details.meeting_link);
                                        toast.success('Link copied successfully!')
                                      }
                                    }}
                                  >
                                    Copy link
                                  </Button>
                                  {
                                    this.state.details.meeting_password ? (
                                      <Label>
                                        {this.state.details.meeting_password}
                                      </Label>
                                    ) : null
                                  }
                                </>
                              ) : (
                                <Button
                                  color="link"
                                  onClick={() => {
                                    if (typeof (navigator.clipboard) == 'undefined') {
                                      var textArea = document.createElement("textarea");
                                      textArea.value = this.state.details.participant_link;
                                      textArea.style.position = "fixed";  //avoid scrolling to bottom
                                      document.body.appendChild(textArea);
                                      textArea.focus();
                                      textArea.select();

                                      try {
                                        var successful = document.execCommand('copy');
                                        var msg = successful ? 'successful' : 'unsuccessful';
                                        toast.success('Link copied successfully!')
                                      } catch (err) {
                                        toast.warning('Was not possible to copy te text:', err);
                                      }

                                      document.body.removeChild(textArea)
                                      return;
                                    } else {

                                      navigator.clipboard.writeText(this.state.details.participant_link);
                                    }
                                    toast.success('Link copied successfully!')
                                  }}
                                >
                                  Copy link
                                </Button>
                              )
                            }

                          </Col>
                        </Row>
                      </section>
                    </div>
                  }
                </div>
                <div className="footer">
                  {
                    (
                      this.state.details.host_id ==
                      this.state.studentId
                    ) ? (
                      <>
                        {
                          this.state.details.status ? (
                            <Button
                              color="primary"
                              onClick={() => {
                                window.open(this.state.details.meeting_link, "_blank")
                              }}
                            >
                              Start
                            </Button>
                          ) : null
                        }

                        <Button color="secondary" outline
                          onClick={() => {
                            let route = "/create-meeting/" + this.state.details.id;
                            this.props.history.push(route);
                          }}
                        >
                          Edit
                        </Button>
                        {
                          this.props.handle == "draft" ? (
                            <Button color="primary"
                              onClick={() => {
                                this.sendMeeting()
                              }}
                            >Send</Button>
                          ) : null
                        }
                      </>
                    ) : null
                  }
                </div>
              </>
            ) : <></>
          }
        </Col>
      </Row >
    );
  }
}

function mapStateToProps(state) {
  return {
    universityLogo: state.profileReducer.universityLogo
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      meetingListAction,
      meetingDetailsAction,
      getProfileAction,
      deleteMeetingAction,
      updateMeetingStatusAction,
      sendMeetingAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CommonList);
