import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
// import { AppHeader } from "@coreui/react";
// import DefaultHeader from "../../containers/DefaultLayout/DefaultHeader";
import PageLoading from "../../common/PageLoading";
import LeftInboxMenu from "../Inbox/LeftInboxMenu";
import CommonList from "./components/CommonList";
import ChatList from "./ChatList.json";

// import inboxicon from "../../assets/img/student/inbox-blue.svg";
import "../Studentprofile/Studentprofile.scss";

class Chatbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StarredChats: false, DeleteChats: false,
      loading: false
    };
  }
  loading = () => <PageLoading loading={true} />;

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  render() {
    return (
      <>
        {/* <AppHeader fixed className="foe-header-container">
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        {
          this.state.loading ? (
            <PageLoading loading={true} />
          ) : null
        } */}
        {
          this.state.loading ? (
            <PageLoading loading={true} />
          ) : null
        }

        <div className="profileBox">
          <LeftInboxMenu title={"Chats"} />
          <Container className='profileContainer foe-inbox-container chatList'>
            <Row>
              <Col xs="12" className="uni-right-card chatDataList">
                <CommonList
                  {...this.props}
                  type={"All Saved Chats"}
                  handle={"allChats"}
                  pathName={"/chats"}
                  pageUrl={"/#/chats"}
                  state={this.state}
                  chatData={ChatList}
                  showLoading={this.showLoading}
                  hideLoading={this.hideLoading}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Chatbox;
