import React from "react";
import { Col, Row } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { SRLWrapper } from "simple-react-lightbox";
// import { Map } from "pigeon-maps";
import FormeeLogo from "../../../assets/img/brand/logo_symbol.svg";
import { ReactComponent as GreenTick } from "../../../assets/img/icons/green_tick.svg";
import { ReactComponent as DocumentBG } from "../../../assets/img/icons/document_bg.svg";
import { ReactComponent as BackArrow } from "../../../assets/img/icons/back-arrow.svg";
import moment from "moment";
import config from "../../../config.json";

const baseUrl = config.baseurl

export default function MeetingDetails() {
  const options = {
    settings: {
      overlayColor: "rgba(0, 0, 0,0.5)",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      showAutoplayButton: false,
      showCloseButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
    },
    caption: {
      cshowCaption: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  let profile = null;
  if (this.props.profile) {
    profile = this.props.profile
    console.log('log profile', profile);
  }

  return (
    <>
      <Row>
        <Col xs="12">
          <div className="head">
            <div className="backBtn d-lg-none" display="md" mobile onClick={() => { this.setState({ MeetingDetailOpen: false }) }}>
              <BackArrow />
            </div>
            <h3>
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${FormeeLogo})`,
                }}
              ></div>{" "}
              Admin
            </h3>
          </div>
        </Col>
        <Col xs="12">
          <div className="mainDate text-center p-15">
            {
              this.state.currentSession ?
                moment(this.state.currentSession.updated_at).format('MM-DD-YYYY') :
                ""
            }
          </div>
          <Scrollbars style={{ width: "100%", height: "calc(100vh - 290px)" }} className="meetingDetailWrap">
            <ul className="chatlisting">
              {this.state.listData ? (
                this.state.listData.map((item, index) => {
                  let docName = "";
                  let docType = "";
                  if (item.message_type == "doc") {
                    docName = item.message.substring(item.message.lastIndexOf('/') + 1);
                    if (docName) {
                      docType = docName.substring(docName.lastIndexOf('.') + 1);
                    }
                  }

                  return (
                    <li
                      key={index}
                      className={
                        item.agent_id
                          ? "adminChat"
                          : "userChat"
                      }
                    >
                      {
                        !item.agent_id ? (
                          profile &&
                            profile.filename ? (
                            <span
                              className="thumbnail"
                              style={{
                                backgroundImage: `url(${baseUrl + profile.filename})`,
                              }}
                            ></span>
                          ) : (
                            <span
                              className="thumbnail"
                              style={{

                              }}
                            ></span>
                          )

                        ) : (
                          <span
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${FormeeLogo})`,
                            }}
                          ></span>
                        )
                      }
                      <div
                        className={`dataBox ${(
                            item.message_type == "map" ||
                            item.message_type == "image"
                          ) ? "imageBox"
                            : ""
                          }`}
                      >
                        <div className="data">
                          {/* Text */}
                          {
                            item.message_type == "text" ?
                              item.message :
                              null
                          }

                          {/* Document */}
                          {
                            item.message_type == "doc" ? (
                              <div
                                className="document"
                                onClick={() => {
                                  window.open(`${item.message}`)
                                }}
                              >
                                <span className="icon">
                                  <span className="type">
                                    {docType}
                                  </span>
                                  <DocumentBG />
                                </span>
                                {docName}
                              </div>
                            ) : null
                          }

                          {/* Image */}
                          {
                            item.message_type == "image" ? (
                              <div className="images">
                                <SRLWrapper options={options}>
                                  <img
                                    src={item.message}
                                    alt=""
                                    onClick={() => {
                                      window.open(`${item.message}`)
                                    }}
                                  />
                                </SRLWrapper>
                              </div>
                            ) : null
                          }

                          {/* Map */}
                          {
                            item.message_type == "map" &&
                              item.lat &&
                              item.lng ? (
                              <div className="map">
                                {/* <Map
																	defaultCenter={[item.lng, item.lat]}
																	defaultZoom={12}
																	width={200}
																	height={110}
																/> */}
                                <img
                                  src={item.message}
                                  height="110"
                                  width="200"
                                  onClick={() => {
                                    window.open(`//www.google.com/maps?lat=${item.lat}&long=${item.lng}`)
                                  }}
                                />
                              </div>
                            ) : null
                          }
                        </div>
                        <div className="footer">
                          {/* Document */}
                          <div className="document">
                            {
                              docType ? (
                                <>
                                  {docType}
                                </>
                              ) : null
                            }
                            {/* {item.document &&
														item.document.hasOwnProperty("name") ? (
															<>
																{item.document &&
																	item.document.size +
																		` ∙ ` +
																		item.document.type}
															</>
														) : null} */}
                          </div>

                          {/* Time */}
                          <div className="time">
                            {moment(item.updated_at).format("MM-DD-YYYY")}{" "}
                            {/* {item.is_user === 1 ? <GreenTick /> : null} */}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="text-center">No chat history available</div>
              )}
              {/* <li className="userChat">
                <span className="thumbnail"></span>
                <div className="dataBox ">
                  <div className="data">test mesage</div>
                  <div className="footer">
                    <div className="document"></div>
                    <div className="time">01-18-2022</div>
                  </div>
                </div>
              </li>
              <li className="adminChat">
                <span className="thumbnail"></span>
                <div className="dataBox ">
                  <div className="data">message</div>
                  <div className="footer">
                    <div className="document"></div>
                    <div className="time">01-18-2022</div>
                  </div>
                </div>
              </li>
              <li
                className="adminChat"
              >
                <span
                  className="thumbnail"
                  style={{
                    // backgroundImage: `url(${baseUrl + profile.logo})`,
                  }}
                ></span>
                <div
                  className={`dataBox`}
                >
                  <div className="data">
                    <div
                      className="document"
                    // onClick={() => {
                    // 	window.open(`${item.message}`)
                    // }}
                    >
                      <span className="icon">
                        <span className="type">
                          pdf
                        </span>
                        <DocumentBG />
                      </span>
                      application form.pdf
                    </div>
                  </div>
                  <div className="footer">
                    <div className="document">pdf</div>
                    <div className="time">01-19-2022</div>
                  </div>
                </div>
              </li>
              <li
                className="userChat"
              >
                <span
                  className="thumbnail"
                  style={{
                    // backgroundImage: `url(${baseUrl + profile.logo})`,
                  }}
                ></span>
                <div
                  className={`dataBox imageBox`}
                >
                  <div className="data">
                    <div className="images">
                      <SRLWrapper options={options}>
                        <img
                          // src={item.message}
                          alt=""
                        // onClick={() => {
                        // 	window.open(`${item.message}`)
                        // }}
                        />
                      </SRLWrapper>
                    </div>
                  </div>
                  <div className="footer">
                    <div className="document"></div>
                    <div className="time">01-19-2022</div>
                  </div>
                </div>
              </li>
              <li
                className="userChat"
              >
                <span
                  className="thumbnail"
                  style={{
                    // backgroundImage: `url(${baseUrl + profile.logo})`,
                  }}
                ></span>
                <div
                  className={`dataBox imageBox`}
                >
                  <div className="data">
                    <div className="map">
                      <img
                        // src={item.message}
                        height="110"
                        width="200"
                      // onClick={() => {
                      // 	window.open(`//www.google.com/maps?lat=${item.lat}&long=${item.lng}`)
                      // }}
                      />
                    </div>
                  </div>
                  <div className="footer">
                    <div className="document"></div>
                    <div className="time">01-19-2022</div>
                  </div>
                </div>
              </li>
              <li
                className="userChat"
              >
                <span
                  className="thumbnail"
                  style={{
                    // backgroundImage: `url(${baseUrl + profile.logo})`,
                  }}
                ></span>
                <div
                  className={`dataBox`}
                >
                  <div className="data">
                    <div
                      className="document"
                    // onClick={() => {
                    // 	window.open(`${item.message}`)
                    // }}
                    >
                      <span className="icon">
                        <span className="type">
                          pdf
                        </span>
                        <DocumentBG />
                      </span>
                      application form.pdf
                    </div>
                  </div>
                  <div className="footer">
                    <div className="document">pdf</div>
                    <div className="time">01-19-2022</div>
                  </div>
                </div>
              </li> */}
            </ul>

          </Scrollbars>
        </Col>
      </Row>
    </>
  );
}
