import React from 'react';
import {confirmAlert} from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import config from "../../config.json";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {MESSAGES} from "../../constant/messages";
const baseurl = `${config.baseurl}`;

export const Favourite = (param, type, type_id, callback) => {
    let fd = new FormData();
    fd.append("type", type);
    fd.append("type_id", type_id);
    fd.append("action_type", "Favourite");
    fd.append("composedmail_id", param.id);
    fd.append("status", 1);
    axios
        .post(baseurl + "/university_mailaciton", fd)
        .then((res) => {
            callback();
            toast.success("Mail added to favourite Successfully.");
        })
        .catch((err) => {
            console.log(err, "Check Fav Failure");
        });
};

export const Unfavourite = (param, type, type_id, callback) => {

    let fd = new FormData();
    fd.append("type", type);
    fd.append("type_id", type_id);
    fd.append("action_type", "Favourite");
    fd.append("composedmail_id", param.id);
    fd.append("status", 0);
    axios
        .post(baseurl + "/university_mailaciton", fd)
        .then((res) => {
            toast.success("Mail removed from favourite Successfully.");
            callback()
        })
        .catch((err) => {
            console.log(err, "Check Fav Failure");
        });
};

export const DeleteMsg = (param, type, type_id, delete_for, callback) => {

    let fd = new FormData();
    fd.append("type", type);
    fd.append("type_id", type_id);
    fd.append("delete_for", delete_for);
    fd.append("composedmail_id", param.id);

    confirmAlert({
        title: "Confirm",
        message: "Are you sure you want to permanently delete this email?",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    axios
                        .post(baseurl + "/delete_sentmail", fd)
                        .then((res) => {
                            toast.success(MESSAGES.MAIL_DELETED_SUCCESSFULLY);
                            callback()
                        })
                        .catch((err) => {
                            console.log(err, "Check Del Failure");
                        });
                },
            },
            {
                label: "No",
                onClick: () => callback(),
            },
        ],
    });
};

export const changeFavourite = (param, type, type_id, callback) => {
    let fd = new FormData();
    fd.append("type", type);
    fd.append("type_id", type_id);
    fd.append("action_type", "Favourite");
    fd.append("composedmail_id", param.id);
    fd.append("status", param.is_starred === 0 ? 1 : 0);
    axios
        .post(baseurl + "/university_mailaciton", fd)
        .then((res) => {
            callback()
            if (param.is_starred === 0) {
                toast.success(MESSAGES.MAIL_ADDED_TO_FAVOURITE_SUCCESSFULLY);
            } else {
                toast.success(MESSAGES.MAIL_REMOVED_FROM_FAVOURITE);
            }
        })
        .catch((err) => {
            //
        });
};

export const createMeeting = (param) => {
    localStorage.setItem("composed_mail_id_for_meeting", param);
    window.location.href = "/#/createMeeting";
};

export const viewMeeting = (param) => {
    localStorage.setItem("mailview", "mail");
    localStorage.setItem("viewmeetingid", param.id);
    localStorage.setItem("meeting_id", param.meeting_id);
    window.location.href = "/#/viewMeeting";
};