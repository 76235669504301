import "../Personalinfo/Personalinfo.css";
import React, { Component } from "react";
import { Card, CardBody, Col, Container, Media, Row, FormGroup, Input, Label, } from "reactstrap";
import locateicon from "../../assets/img/university/locate-icon.svg";
import backarrow from "../../assets/img/university/back_arrow.svg";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../common/FormError";
import PageLoading from "../../common/PageLoading";
import { MESSAGES } from "../../constant/messages.js";
import {
  ALN_REGEX,
  CODE_REGEX,
  MIN_MAX_REGEX,
  NAME_REGEX,
  PAS_MAX_REG,
  ZIP_MAX_REGEX,
} from "../../constant/constant.js";
import submitForm from "../../utils/submitForm.js";
import "../../index.css"
import { Link, withRouter } from "react-router-dom";

const baseurl = `${config.baseurl}`;

const senderType = [
  {
    label: 'Parent/Guadian',
    value: '0'
  },
  {
    label: 'Student',
    value: '1'
  },
];
const grades = [
  { label: 'Engineering and Technology', value: 'engAndTech' },
  { label: 'Sciences', value: 'sciences' },
  { label: 'Arts', value: 'arts' },
  { label: 'Business, Management & Economics', value: 'businessManagement' },
  { label: 'Law, Politics, Social, Community Service & Teaching', value: 'lawPolitics' },
  { label: 'English for Academic studies', value: 'englishAcademic' },
  { label: 'Health Science, Medical, Nursing & Paramedic', value: 'healthMedical' }
]
const marritalStatuses = [
  {
    label: 'Single',
    value: '1'
  },
  {
    label: 'Married',
    value: '2'
  },
  {
    label: 'Widowed',
    value: '3'
  },
  {
    label: 'Separated',
    value: '4'
  },
  {
    label: 'Divorced',
    value: '5'
  }
]
const genders = [
  {
    label: 'Male',
    value: '1'
  },
  {
    label: 'Female',
    value: '2'
  },
  {
    label: 'Non-binary',
    value: '3'
  },
  {
    label: 'I dont want to disclose',
    value: '4'
  }
];
const titles = ["Mr", "Mrs", "Miss", "Ms"];

const studyLevel = [
  {
    label: 'Primary',
    value: '1'
  },
  {
    label: 'Secondary',
    value: '2'
  },
  {
    label: 'Undergraduate',
    value: '3'
  },
  {
    label: 'Postgraduate certificate/Diploma',
    value: '4'
  },
  {
    label: 'Master Degree',
    value: '5'
  },
  {
    label: 'Doctoral Degree (Phd, M.D)',
    value: '6',
  }
];
const studyYear = [
  {
    label: 'Don\'t Know',
    value: 'Don\'t Know'
  },
  {
    label: moment().year().toString(),
    value: moment().year().toString()
  },
  {
    label: (moment().year() + 1).toString(),
    value: (moment().year() + 1).toString()
  },
  {
    label: (moment().year() + 2).toString(),
    value: (moment().year() + 2).toString()
  },
  {
    label: (moment().year() + 3).toString(),
    value: (moment().year() + 3).toString()
  },
  {
    label: (moment().year() + 4).toString(),
    value: (moment().year() + 4).toString()
  },
  {
    label: (moment().year() + 5).toString(),
    value: (moment().year() + 5).toString()
  },
];
const councellingMode = [
  {
    label: 'In - Person',
    value: '0'
  },
  {
    label: 'Virtual Councelling',
    value: '1'
  },
];

const dob_maxdate = new Date();
dob_maxdate.setYear(dob_maxdate.getYear() - 5);

function deltaDate(input, days, months, years) {
  return new Date(
    input.getFullYear() + years,
    input.getMonth() + months,
    Math.min(
      input.getDate() + days,
      new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
    )
  );
}


class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
    this.state = {
      agentId: 0,
      officeId: 0,
      country: [],
      studyCountry: [],
      sender_type: "1",
      fname_sender: "",
      lname_sender: "",
      fname_student: "",
      lname_student: "",
      dateofbirth: "",
      flanguage: "",
      citiship: "",

      //scountry: "",
      spassport: "",
      sgender: "",
      smarital: "",

      study_country: "",
      study_level: "",
      study_year: "",
      councelling_mode: "",
      message_details: "",

      slevel: "",
      slike: "",
      items: [],
      overall_star: 0,
      agentdetails: [],
      agentdetails2: [],

      branchname: "",
      contact_address: "",

      review: "",
      likecount: "",
      rank: "",
      edusublevel: [],

      formData: new FormData(),
      errors: {},
    };
  }

  handleLoad() {
    //window.scrollTo(0, 0);
    $("html, body").animate(
      {
        scrollTop: $("#SendMessageHeader").offset().top - 170, //id of div to be scrolled
        scrollTop: $("#SendMessageHeader2").offset().top - 170, //id of div to be scrolled
      },
      1000
    );
  }


  componentDidMount() {
    this.getProgramCoutries();
    const query = new URLSearchParams(this.props.location.search);
    const goto = query.get('show');

    $(document).ready(function () {
      $(window).scrollTop(0, 0);
      $(this).scrollTop(0, 0);

      //window.scrollTo(0, 0);
    });

    $(window).one("scroll", function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    let agentId = 0
    let officeId = 0
    if (
      this.props
      && this.props.match
      && this.props.match.params
      && this.props.match.params.id
    ) {
      agentId = this.props.match.params.id
    }

    if (
      this.props
      && this.props.match
      && this.props.match.params
      && this.props.match.params.officeId
    ) {
      officeId = this.props.match.params.officeId
    }

    this.setState({
      agentId,
      officeId
    }, () => {
      this.getGeneralData();
    })
  }
  getProgramCoutries = () =>
    axios
      .get(baseurl + "/program-country")
      .then((response) => {
        if (response.data.data) {
          let countryOptions = [];
          response.data.data.map((val) => {
            countryOptions.push({
              value: val.id,
              label: val.country_name,
            });
          });

          this.setState({ studyCountry: countryOptions });
        }
      })
      .catch((e) => this.setState({ loading: false }));
  getGeneralData = () => {
    this.showLoader();
    const id = this.state.agentId;
    const agent_branch_id = this.state.officeId;
    //console.log(agent_branch_id);
    const agentDetailsReq = axios.get(baseurl + "/get_agentdetails/" + id + "/" + agent_branch_id);
    const countryReq = axios.get(baseurl + "/get_country");
    const agentStudentReviewsReq = axios.get(
      baseurl + "/get_agent_student_reviews_byagentid/" + id
    );
    const agentsDataReq = axios.get(
      baseurl + "/getAgentsData/" + this.state.agentId
    );
    const studentId = localStorage.getItem("studentid");
    const StuDataReq = axios.get(baseurl + "/get_generalinfo/" + studentId);

    Promise.all([agentDetailsReq, agentStudentReviewsReq, agentsDataReq, countryReq, StuDataReq])
      .then(([agentDetailsRes, agentStudentReviewsRes, agentsDataRes, countryRes, StuDataRes]) => {

        console.log('log agentDetailsRes', agentDetailsRes.data);
        this.setState({
          agentdetails: agentDetailsRes.data
            ? agentDetailsRes.data.agentdetails
            : this.state.agentdetails,
          agentdetails2: agentDetailsRes.data
            ? agentDetailsRes.data.agent_target_branch
            : this.state.agentdetails,
          branchname: agentDetailsRes.data.agent_target_branch.branch_name,
          contact_address:
            agentDetailsRes.data.agent_target_branch.address,
          items: agentStudentReviewsRes.data.data,
          likecount: agentsDataRes.data.fav_count,
          rank: agentsDataRes.data.rank,
          country: countryRes.data,
        });
        if (StuDataRes.data && StuDataRes.data.length && StuDataRes.data.length > 0) {
          let studentData = StuDataRes.data[0]
          if (studentData) {
            this.setState({
              fname_student: studentData.first_name,
              lname_student: studentData.last_name,
              dateofbirth: new Date(studentData.dateof_birth),
              flanguage: studentData.first_language,
              citiship: countryRes.data.find(con => con.country_name.toLowerCase() === studentData.country_name.toLowerCase())?.id,
              spassport: studentData.passport_no,
              sgender: genders.find(gen => gen.label.toLowerCase() === studentData.gender.toLowerCase())?.value,
              smarital: marritalStatuses.find(ms => ms.label.toLowerCase() === studentData.marital_status.toLowerCase())?.value,
            });
          }
        }
        this.hideLoader();
      })
      .catch((e) => {
        console.log('e: ', e);
        toast.error(MESSAGES.DEFAULT_ERROR_MESSAGE);
        this.hideLoader();
      });
  };

  clearAll = (param) => (e) => {
    /*if (param == 0) {*/
    this.setState({
      sender_type: "1",
      fname_sender: "",
      lname_sender: "",
      fname_student: "",
      lname_student: "",
      dateofbirth: "",
      flanguage: "",
      citiship: "",
      spassport: "",
      sgender: "",
      smarital: "",
      study_country: "",
      study_level: "",
      study_year: "",
      councelling_mode: "",
      message_details: "",

    });
    /*}*/
  };

  handleSenderTypeChange = (event) => {
    this.setState({ sender_type: event.target.value }, () => {
    });
  };

  handleTargetChange = (event, trim = false) => {
    let val = event.target.value;
    if (trim) {
      val = String(val).trim();
    }
    this.setState({ [event.target.name]: val });
  };

  handleDateChange = (date) => {
    this.setState({ dateofbirth: date });
  };

  handleLayoutChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleChangeOne = (e) => {
    this.setState({
      review: e.target.value,
    });
  };

  handleSCitishipChange = (event) => {
    this.setState({ citiship: event.target.value }, () => {
    });
  };

  handleGenderChange = (event) => {
    this.setState({ sgender: event.target.value }, () => {
    });
  };

  handleMaritalChange = (event) => {
    this.setState({ smarital: event.target.value }, () => {
    });
  };

  handleStudyCountryChange = (event) => {
    this.setState({ study_country: event.target.value }, () => {
    });
  };

  handleStudyLevelChange = (event) => {
    this.setState({ study_level: event.target.value }, () => {
    });
  };

  handleSYearChange = (event) => {
    this.setState({ study_year: event.target.value }, () => {
    });
  };

  handleCModeChange = (event) => {
    this.setState({ councelling_mode: event.target.value }, () => {
    });
  };

  handdleSendMessage = (e) => {
    /*const student_id = localStorage.getItem("studentid");
    const agent_id = localStorage.getItem("agentid");
 
    //e.preventDefault();
 
    const products = {
      agent_id: agent_id,
      student_id: student_id,
      no_of_ratings: this.state.selectedOption,
      review_message: this.state.review,
    };
 
    const uri = baseurl + "/store_agent_student_reviews";
 
    axios.post(uri, products).then((response) => {
      this.props.history.push("/preferedpartnerabout");
      this.props.history.push("/preferedpartnerabout?show=contact");
      toast.success(MESSAGES.SAVED_SUCCESSFULLY);
    });*/
  };

  validate = () => {
    const errors = {};
    //const semail = this.state.semail.trim();

    const regAln = ALN_REGEX;
    const pasmax = PAS_MAX_REG;
    const zipmax = ZIP_MAX_REGEX;
    const minmax = MIN_MAX_REGEX;
    const code = CODE_REGEX;

    const regNam = NAME_REGEX;

    if (this.state.sender_type === "") {
      errors.sender_type = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.sender_type === "0" && this.state.fname_sender === "") {
      errors.fname_sender = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.sender_type === "0" && this.state.lname_sender === "") {
      errors.lname_sender = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.fname_student === "") {
      errors.fname_student = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.lname_student === "") {
      errors.lname_student = MESSAGES.THIS_IS_REQUIRED;
    }
    if ($("#dateofbirth").val().trim() === "") {
      errors.dateofbirth = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.flanguage === "") {
      errors.flanguage = MESSAGES.THIS_IS_REQUIRED;
    }
    if (this.state.citiship === "") {
      errors.citiship = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.spassport === "") {
      errors.spassport = MESSAGES.THIS_IS_REQUIRED;
    } else if (!this.state.spassport.match(pasmax)) {
      errors.spassport = MESSAGES.ENTER_VALID_NUMBER;
    } else if (!this.state.spassport.match(regAln)) {
      errors.spassport = MESSAGES.ENTER_VALID_NUMBER;
    }
    if (this.state.smarital === "") {
      errors.smarital = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.sgender === "") {
      errors.sgender = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.study_country === "") {
      errors.study_country = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.study_level === "") {
      errors.study_level = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.study_year === "") {
      errors.study_year = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.councelling_mode === "") {
      errors.councelling_mode = MESSAGES.PLEASE_SELECT_MESSAGE;
    }
    if (this.state.message_details === "") {
      errors.message_details = MESSAGES.THIS_IS_REQUIRED;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  onSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors });
    console.log("error: " + JSON.stringify(errors));
    if (errors) return;

    this.state.formData.append("student_id", localStorage.getItem("studentid"));
    this.state.formData.append("agent_id", this.state.agentId);
    this.state.formData.append("agent_branch_id", this.state.officeId);
    this.state.formData.append("sender_type", this.state.sender_type);
    this.state.formData.append("fname_parent", this.state.fname_sender);
    this.state.formData.append("lname_parent", this.state.lname_sender);
    this.state.formData.append("fname_student", this.state.fname_student);
    this.state.formData.append("lname_student", this.state.lname_student);
    this.state.formData.append("dateofbirth", $("#dateofbirth").val());
    this.state.formData.append("flanguage", this.state.flanguage);
    this.state.formData.append("citiship", this.state.citiship);
    this.state.formData.append("spassport", this.state.spassport);
    this.state.formData.append("sgender", this.state.sgender);
    this.state.formData.append("smarital", this.state.smarital);
    this.state.formData.append("scountry", this.state.study_country);
    this.state.formData.append("slevel", this.state.study_level);
    this.state.formData.append("syear", this.state.study_year);
    this.state.formData.append("councelling_mode", this.state.councelling_mode);
    this.state.formData.append("message", this.state.message_details);

    //this.state.formData.append("student_id", 1);
    this.showLoader();
    submitForm(
      "form-data",
      this.state.formData,
      (msg) => {
        this.hideLoader();
        toast.success(msg.message);
        // this.clearAll();
        this.props.history.push(`/preferedpartnerabout/${this.state.agentId}?show=contact`);
        this.setState({ errors: {} });
        /*setTimeout(function () {
          $("#tab2").addClass("active");
          $("#tab1").removeClass("active");
          $(".generaltab").removeClass("active");
          $(".educationtab").addClass("active");
        }, 3000);*/
      },
      "store-contact-us"
    );
  };

  showLoader = () => {
    this.setState({ loading: true });
  };

  hideLoader = () => {
    this.setState({ loading: false });
  };

  render() {
    const handleKeyDownEvent = (e) => (
      e.keyCode === 69 ||
      e.keyCode === 190 ||
      e.keyCode === 186 ||
      e.keyCode === 187 ||
      e.keyCode === 189
    ) && e.preventDefault()

    console.log("dateofbirth", this.state.dateofbirth)
    const prods = this.state.edusublevel.map((ub, index) => {
      if (this.state.higheduleveltype == "sublevel") {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            key={sublevel.id}
            value={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );
        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      } else if (this.state.higheduleveltype == "mainlevel") {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            key={sublevel.id}
            value={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );

        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      } else {
        const sublevel = ub.sublevel.map((sublevel) =>
          <option
            value={sublevel.id}
            key={sublevel.id}
          >
            {sublevel.educationlevel_name}
          </option>
        );
        return (
          <React.Fragment key={index.toString()}>
            <option value={ub.id}>{ub.mainlevel}</option>
            {sublevel}
          </React.Fragment>
        );
      }
    });

    const imgpath = `${config.baseurl}`;
    let overall_star = 0;

    this.state.items.forEach((item, index) => {
      overall_star = overall_star + item.no_of_ratings;
    });
    const overall_review = overall_star / this.state.items.length;

    {/*<div className="animated fadeIn gray-bg-300 foe-viewus">
        <form className="was-validated" onSubmit={this.handleSubmit}>
          <div className="container-fluid px-0">
            <div className="top-section">
              <div className="container mb-5">

                 <div className="col-md-10 col-lg-8 col-12 leave-review mx-auto">
                    <a
                      className="col-6 backarrow mt-5 pl-md-5 pt-3"
                      href="/#/preferedpartnerabout?show=contact"
                    >
                      <span className="pr-1">
                        <img src={backarrow} alt="" />
                      </span>
                      Back {localStorage.getItem("ContactUsBranchID")}
                    </a>
                  </div>*/}

    return (
      <div id="SendMessageHeader" className="foe-pp-container sendMessageSec">
        {/* <HeaderWithLogoUserNoti /> */}
        {this.state.loading ? (<PageLoading />) : null}
        {/* <AppHeader fixed className="foe-header-container headerWithLogoUserNoti">
          <Suspense>
            <HeaderWithLogoUserNoti />
          </Suspense>
        </AppHeader> */}

        <div className="foe-student-box">
          <Container>
            <Row>

              <Col xs="12" sm="12" xl="12" md="12" lg="12">
                <Card className="uni-right-card">
                  <CardBody>
                    <div className="row col-12 pr-0">
                      <div className="col-md-12 col-lg-12 col-xl-12 d-flex justify-content-start">

                        <div className="personalinfo">
                          <div className="row">
                            <div className="col-md-2 per-box py-4 valigntop">
                              <Link
                                className=" backarrow mt-0 pl-md-5 pt-1 ml-5"
                                to={`/preferedpartnerabout/${this.state.agentId}`}
                              >
                                <span className="pr-1">
                                  <img src={backarrow} alt="" />
                                </span>
                                Back
                                {/*{localStorage.getItem("ContactUsBranchID")}*/}
                              </Link>
                            </div>
                            <div className="col-md-10 per-box py-4">
                              <div id="SendMessageHeader2"
                                className="destination-box row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                                <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-header">
                                        Contact to
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-name">
                                        {/*{console.log(JSON.stringify(this.state.agentdetails2))}*/}
                                        {this.state.agentdetails.company_name},  {this.state.agentdetails2.branch_name}
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-address">
                                        <img
                                          src={locateicon}
                                          className="ag-icon mr-2"
                                        />
                                        {this.state.contact_address}
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12">
                                    <FormGroup>
                                      <Label className="uni-label-pp-address">
                                        <hr className="uni-label-pp-hr" />
                                      </Label>
                                    </FormGroup>
                                  </div>
                                  <div className="col-md-12 valigntop2">
                                    <span className="spanLeft ml-0">
                                      <FormGroup>
                                        <Label className="uni-label-pp-header">
                                          I am a...
                                        </Label>
                                      </FormGroup>
                                    </span>
                                    <span className="spanRight link-custom" onClick={this.clearAll(this.state.selectedIndex)}>
                                      Clear All
                                    </span>
                                  </div>


                                  <div className="personal-box-inner row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-0">
                                    <div className="col-md-6">
                                      <FormGroup>
                                        {/*{console.log("Sender Type: " + this.state.sender_type)}*/}
                                        <div className="select-wrapper">
                                          <Label className="uni-label pp-textbox">
                                            <Input
                                              type="select"
                                              id="sender_type"
                                              onChange={this.handleSenderTypeChange}
                                              className="pp-selectbox alignLeft"
                                              value={this.state.sender_type}
                                            >
                                              {senderType.map((sender_type) =>
                                                <option
                                                  key={sender_type.value}
                                                  value={sender_type.value}
                                                >
                                                  {sender_type.label}
                                                </option>
                                              )}
                                            </Input>
                                          </Label>
                                        </div>
                                      </FormGroup>
                                      {this.state.errors && this.state.errors.sender_type &&
                                        <FormError error={this.state.errors.sender_type} className="error" />}
                                    </div>
                                    <div className="col-md-6">
                                      <FormGroup>
                                        <Label className="uni-label">
                                          <span style={{ color: 'red' }}></span>
                                        </Label>

                                      </FormGroup>

                                    </div>
                                    {this.state.sender_type === "0" ? (<>

                                      <div className="col-md-6 mt-2 alignLeft">
                                        <FormGroup>
                                          <Label className="uni-label2 ">
                                            FIRST NAME <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-control"
                                              placeholder="Enter First Name..."
                                              name="fname_sender"
                                              onChange={this.handleTargetChange}
                                              value={this.state.fname_sender}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.fname_sender &&
                                          <FormError error={this.state.errors.fname_sender} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-2 mb-2 alignRight">
                                        <FormGroup>
                                          <Label className="uni-label2">
                                            LAST NAME <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-0"
                                              placeholder="Enter Last Name..."
                                              name="lname_sender"
                                              onChange={this.handleTargetChange}
                                              value={this.state.lname_sender}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.lname_sender &&
                                          <FormError error={this.state.errors.lname_sender} className="error" />}
                                      </div>
                                    </>) : " "}

                                  </div>

                                  <div className="personal-box-inner row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                    <div className="pr-header pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                      <h3>Student Information
                                        <small>(As indicated on your passport)</small>
                                      </h3>
                                    </div>

                                    <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                      <div className="col-md-6 mt-2 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2">
                                            FIRST NAME <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-control"
                                              placeholder="Enter First Name..."
                                              name="fname_student"
                                              onChange={this.handleTargetChange}
                                              value={this.state.fname_student}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.fname_student &&
                                          <FormError error={this.state.errors.fname_student} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-2 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2">
                                            LAST NAME <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-control"
                                              placeholder="Enter Last Name..."
                                              name="lname_student"
                                              onChange={this.handleTargetChange}
                                              value={this.state.lname_student}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.lname_student &&
                                          <FormError error={this.state.errors.lname_student} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            DATE OF BIRTH <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <DatePicker
                                              selected={this.state.dateofbirth}
                                              onChange={this.handleDateChange}
                                              dateFormat="yyyy-MM-dd"
                                              className="form-control "
                                              id="dateofbirth"
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              placeholderText="YYYY-MM-DD"
                                              maxDate={deltaDate(new Date(), 0, 0, -5)} //Default minimum > 5years old
                                              name="dateofbirth2"
                                              minDate={new Date('01-01-1950')}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.dateofbirth &&
                                          <FormError error={this.state.errors.dateofbirth} className="error" />}
                                        {/*{this.state.errors.dateofbirth &&
                                                                    <FormError error={this.state.errors.dateofbirth} className="error"/>}*/}
                                      </div>
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2">
                                            FIRST LANGUAGE <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-control"
                                              placeholder="Enter First language..."
                                              name="flanguage"
                                              onChange={this.handleTargetChange}
                                              value={this.state.flanguage}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.flanguage &&
                                          <FormError error={this.state.errors.flanguage} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            Country of Citizenship <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox ">
                                            <Input
                                              type="select"
                                              id="citiship"
                                              onChange={this.handleSCitishipChange}
                                              className=""
                                              value={this.state.citiship}
                                            >
                                              <option value="">Select..</option>
                                              {this.state.country.map((country) => (
                                                <option
                                                  key={country.id}
                                                  value={country.id} selected={this.state.citiship}
                                                >
                                                  {country.country_name}
                                                </option>
                                              ))}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.citiship &&
                                          <FormError error={this.state.errors.citiship} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">Passport Number <span
                                            style={{ color: 'red' }}>*</span></Label>
                                          <div className="uni-label2 pp-textbox">
                                            <Input
                                              type="text"
                                              maxLength="30"
                                              className="form-control"
                                              placeholder="Enter Passport Number..."
                                              name="spassport"
                                              onChange={this.handleTargetChange}
                                              value={this.state.spassport}
                                            />
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.spassport &&
                                          <FormError error={this.state.errors.spassport} className="error" />}
                                      </div>
                                      <div className="col-md-6">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            Gender <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox">
                                            <Input
                                              type="select"
                                              id="sgender"
                                              onChange={this.handleGenderChange}
                                              className=""
                                              value={this.state.sgender}
                                            >
                                              <option value="">Select..</option>
                                              {genders.map((gender) =>
                                                <option
                                                  key={gender.value}
                                                  value={gender.value}
                                                >
                                                  {gender.label}
                                                </option>
                                              )}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.sgender &&
                                          <FormError error={this.state.errors.sgender} className="error" />}
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            Marital Status <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox">
                                            <Input
                                              type="select"
                                              id="smarital"
                                              onChange={this.handleMaritalChange}
                                              className=""
                                              value={this.state.smarital}
                                            >
                                              <option value="">Select..</option>
                                              {marritalStatuses.map((marritalStatus) =>
                                                <option
                                                  key={marritalStatus.value}
                                                  value={marritalStatus.value}
                                                >
                                                  {marritalStatus.label.charAt(0).toUpperCase() + marritalStatus.label.slice(1)}
                                                </option>
                                              )}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.smarital &&
                                          <FormError error={this.state.errors.smarital} className="error" />}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="personal-box-inner row px-0 px-sm-0 px-md-0 px-lg-4 px-xl-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                                    <div className="pr-header pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0 mb-3">
                                      <h3>General Information</h3>
                                    </div>

                                    <div className="row pl-4 px-sm-4 px-md-0 px-lg-0 px-xl-0">
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            Intended country of study : <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox">
                                            <Input
                                              type="select"
                                              id="study_country"
                                              onChange={this.handleStudyCountryChange}
                                              className=""
                                              value={this.state.study_country}
                                            >
                                              <option value="">Select..</option>
                                              {this.state.studyCountry.map((country) => (
                                                <option
                                                  key={country.value}
                                                  value={country.value}
                                                >
                                                  {country.label}
                                                </option>
                                              ))}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.study_country &&
                                          <FormError error={this.state.errors.study_country} className="error" />}
                                      </div>
                                      <div className="col-md-6 mt-0 mb-0">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox alignLeft2">
                                            Intended level of study: <span style={{ color: 'red' }}>*</span></Label>
                                          <div className="uni-label2 pp-textbox alignLeft2">
                                            <Input
                                              type="select"
                                              id="study_level"
                                              onChange={this.handleStudyLevelChange}
                                              className=""
                                              value={this.state.study_level}
                                            >
                                              <option value="">Select..</option>
                                              {studyLevel.map((level) => (
                                                <option
                                                  key={level.value}
                                                  value={level.value}
                                                >
                                                  {level.label}
                                                </option>
                                              ))}
                                            </Input>
                                          </div>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.study_level &&
                                          <FormError error={this.state.errors.study_level} className="error" />}
                                      </div>
                                      <div className="col-md-6">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox">
                                            When you would like to start studying?
                                            <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox">
                                            <Input
                                              type="select"
                                              id="study_year"
                                              onChange={this.handleSYearChange}
                                              className=""
                                              value={this.state.study_year}
                                            >
                                              <option value="">Select..</option>
                                              {studyYear.map((syear) =>
                                                <option
                                                  key={syear.value}
                                                  value={syear.value}
                                                >
                                                  {syear.label}
                                                </option>
                                              )}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.study_year &&
                                          <FormError error={this.state.errors.study_year} className="error" />}
                                      </div>
                                      <div className="col-md-6">
                                        <FormGroup>
                                          <Label className="uni-label2 pp-textbox alignLeft2">
                                            Preferred mode of counselling?
                                            <span style={{ color: 'red' }}>*</span>
                                          </Label>
                                          <Label className="uni-label2 pp-textbox alignLeft2">
                                            <Input
                                              type="select"
                                              id="councelling_mode"
                                              onChange={this.handleCModeChange}
                                              className=""
                                              value={this.state.councelling_mode}
                                            >
                                              <option value="">Select..</option>
                                              {councellingMode.map((cmode) =>
                                                <option
                                                  key={cmode.value}
                                                  value={cmode.value}
                                                >
                                                  {cmode.label}
                                                </option>
                                              )}
                                            </Input>
                                          </Label>
                                        </FormGroup>
                                        {this.state.errors && this.state.errors.councelling_mode &&
                                          <FormError error={this.state.errors.councelling_mode} className="error" />}
                                      </div>

                                      <div className="col-md-12 mt-4">

                                        <Label className="uni-label2 font-raleway mb-3">
                                          Message :
                                          <span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Label className="uni-label2">
                                          <textarea
                                            id="message_details"
                                            value={this.state.message_details}
                                            className="form-control  form-control2 "
                                            placeholder="Type here"
                                            onChange={this.handleTargetChange}
                                            name="message_details"
                                          />
                                        </Label>
                                        {this.state.errors && this.state.errors.message_details &&
                                          <FormError error={this.state.errors.message_details} className="error" />}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                            <hr className="uni-label-pp-hr2" />
                          </div>
                          <div className="align-center pb-4 ml-0 sendMessage mt-4 vertical-center">
                            <a
                              className="font-11 btn-primary3 btn-primary3-font inti-btn active mt-3 black-outline box-shadow-high hand-cursor"
                              /*onClick={this.handdleSendMessage()}*/
                              onClick={this.onSubmit}
                            >
                              SEND
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    );
  }
}

export default withRouter(SendMessage);
