import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import PageLoading from "../../../common/PageLoading";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../LeftInboxMenu";
import CommonListing from "../CommonListing";
import inboxicon from "../../../assets/img/student/inbox-blue.svg";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import {
  getInboxAction,
  getUnreadInboxCountAction
} from "../../../store/actions";
import "../../Studentprofile/Studentprofile.scss";

class Inbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      unreadCount: 0,
      studentId: 0
    }
  }

  componentDidMount() {
    this.getUnreadCount()
    let param = localStorage.getItem("studentid");
    this.setState({
      studentId: param
    })
  }

  getUnreadCount = () => {
    // new Promise((resolve, reject) => {
    this.props.getUnreadInboxCountAction(
      {
        student_id: localStorage.getItem("studentid"),
        handle: 'unreadStudent'
      },
      (response) => {
        if (response.success) {
          this.setState({
            unreadCount: response.data.count
          })
        }
        // resolve();
      },
      (err) => {
        console.log('errrrrrrrr', err);
        // reject()
      }
    )
    // })
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  loading = () => <PageLoading loading={true} />;

  render() {
    return (
      <>
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={this.state.loading} />
          ) : null
        }

        {/* <AppHeader fixed className="foe-header-container">
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader> */}
        <div className="profileBox">
          <LeftInboxMenu title={"Inbox"} />
          <Container className='profileContainer foe-inbox-container'>
            <Row>
              <Col xs="12" className="uni-right-card rightInboxSec">
                <CommonListing
                  {...this.props}
                  type={"Inbox"}
                  pathName={"/viewMessageInbox"}
                  icon={inboxicon}
                  pageUrl={"inbox"}
                  getUrl={"get_inboxemail"}
                  handle={""}
                  showLoading={this.showLoading}
                  hideLoading={this.hideLoading}
                  unreadCount={this.state.unreadCount}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    inboxList: state.inboxReducer.inboxList
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getInboxAction,
      getUnreadInboxCountAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
