import React, { useState } from "react";
import moment from 'moment';
import { } from "reactstrap";
// import PendingMeetingData from "../PendingMeetingDetail.json"

export default function MeetingDetails(props) {
  // props.details = PendingMeetingData;

  if (!props.details) {
    return <></>
  }
  const rsvpDetails = (status) => {
    switch (status) {
      case 1:
        return { backgroundColor: "#d0d4d9", status: 'Pending', color: "#444444" };
      case 2:
        return { backgroundColor: "#2ECC71", status: 'Attending', color: "#fff" };
      case 3:
        return { backgroundColor: "#8c6ceb", status: 'Maybe', color: "#fff" };
      case 4:
        return { backgroundColor: "#f54a4a", status: 'Rejected', color: "#fff" };
      default:
        return { backgroundColor: "#d0d4d9", status: 'Pending', color: "#444444" };
    }
  }
  return (<>

    <div className="title">
      <span className="timeAgo">{props.details.meeting_created_ago}</span>
      <h2>
        {props.details.title}
      </h2>
      <div className="summary">
        <span className="pr-15">{moment(props.details.meeting_date).format("ddd DD MMMM")}</span> | <span className="pl-15 pr-10">{props.details.meeting_start_time_val} - {props.details.meeting_end_time}</span>
        <small>* {props.details.timezone.label} - Time zone</small>
      </div>
    </div>
    <div className="description">
      <section>
        <strong className="title">Details</strong>
        <div className="content" dangerouslySetInnerHTML={{ __html: props.details.description }}></div>
      </section>

      <section>
        <strong className="title">Participants</strong>
        <ul className="participantsList">
          {
            props.details.participants && props.details.participants.map((item, index) => {
              if (!item.participant) {
                return false
              }
              const personName = item.participant.first_name;
              if (!personName) {
                return
              }
              const rsvp = rsvpDetails(item.status)
              return (
                <li>
                  <span style={{ backgroundColor: item.participant.color_code }}>{personName.charAt(0)}</span>
                  <em>
                    {
                      item.participant.first_name +
                      " " +
                      item.participant.last_name
                    }
                  </em>
                  <em style={{ backgroundColor: rsvp.backgroundColor, color: rsvp.color }}>{rsvp.status}</em>
                </li>
              )
            })
          }
        </ul>
      </section>
    </div>
  </>)
}
