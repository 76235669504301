import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
import { AppHeader } from "@coreui/react";
import { ToastContainer } from "react-toastify";
import PageLoading from "../../../common/PageLoading";
import DefaultHeader from "../../../containers/DefaultLayout/DefaultHeader";
import LeftInboxMenu from "../LeftInboxMenu";
import CommonListing from "../CommonListing";

import inboxicon from "../../../assets/img/student/inbox-blue.svg";
import "../../Studentprofile/Studentprofile.scss";
class Starred extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  showLoading = () => {
    this.setState({
      loading: true
    })
  }

  hideLoading = () => {
    this.setState({
      loading: false
    })
  }

  loading = () => <PageLoading loading={this.state.loading} />;

  render() {
    return (
      <>
        <ToastContainer />
        {
          this.state.loading ? (
            <PageLoading loading={this.state.loading} />
          ) : null
        }
        {/* <AppHeader fixed className="foe-header-container">
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={(e) => this.signOut(e)} />
          </Suspense>
        </AppHeader> */}

        <div className="profileBox">
          <LeftInboxMenu title={"Starred"} />
          <Container className='profileContainer foe-inbox-container'>
            <Row>
              <Col xs="12" className="uni-right-card rightInboxSec">
                <CommonListing
                  {...this.props}
                  type={"Starred"}
                  pathName={"/viewMessageInbox"}
                  icon={inboxicon}
                  pageUrl={"starredInbox"}
                  getUrl={"get_inboxemail"}
                  handle={"starred"}
                  showLoading={this.showLoading}
                  hideLoading={this.hideLoading}
                  unreadCount={0}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Starred;
